import React from 'react';

import UserJourneyContext from './context';
import { UserJourneyEntry } from './types';

interface UserJourneyProps {
  sessionId: string;
  entries: UserJourneyEntry[];
  utmParams: Record<string, string> | null;
}

const UserJourneyProvider: React.FC<UserJourneyProps> = ({
  children,
  sessionId,
  entries,
  utmParams,
}) => {
  return (
    <UserJourneyContext.Provider value={{ sessionId, entries, utmParams }}>
      {children}
    </UserJourneyContext.Provider>
  );
};

export default UserJourneyProvider;
