import React from 'react';

import { UserJourneyData } from './types';

const UserJourneyContext = React.createContext<UserJourneyData>({
  sessionId: '',
  entries: [],
  utmParams: null,
});

export default UserJourneyContext;
