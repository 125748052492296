import { extendTheme } from '@chakra-ui/react';

const CardV2 = extendTheme({
  parts: [
    'card',
    'copy',
    'overline',
    'heading',
    'content',
    'action',
    'image',
    'color',
  ],
  baseStyle: {
    card: {
      width: 'full',
      height: { base: 'none', lg: 550 },
      maxWidth: { base: 'none', sm: '550px', md: '696px', laptop: 360 },
      maxHeight: { base: 'none', sm: 'none', laptop: 550 },
      fontSize: { base: 'sm', md: 'md' },
      textAlign: { base: 'left', laptop: 'center' },
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '2xl',
      justifyContent: 'space-between',
      background: 'white',
      transition: 'all ease .2s',
      _hover: {
        filter: 'drop-shadow(0 0 24px rgba(0, 0, 0, .15))',
        transition: 'all ease .2s',
        cursor: 'pointer',
      },
    },
    content: {
      alignItems: { base: 'flex-start', laptop: 'center' },
      width: 'full',
      height: 'full',
      padding: {
        base: '16px 8px 4px 16px',
        laptop: '32px 16px 0px 16px',
        lg: '40px 30px 0px 30px',
      },
      background: 'white',
      transition: 'all ease .3s',
    },
    copy: {
      lineHeight: 'shorter',
    },
    overline: {
      display: { base: 'none', laptop: 'block' },
      marginBottom: { base: 1, sm: 2, md: 4 },
      color: `#747474`,
      fontSize: { base: 'xs', md: 'sm' },
      fontWeight: 'black',
      textTransform: 'uppercase',
      lineHeight: 'shorter',
      letterSpacing: 'widest',
      wordSpacing: '0.125em',
    },
    heading: {
      marginBottom: [1, null, null, null, 2, 4],
      fontSize: { base: 'md', md: '2xl', laptop: '3xl' },
      color: 'wl-blue.navy',
      lineHeight: 'shorter',
    },
    action: {
      display: { base: 'none', laptop: 'block' },
      fontWeight: 'bold',
      overflow: 'hidden',
      transition: 'all ease .3s',
      width: { base: 'full', laptop: 206 },
      height: '44px',
      marginBottom: 8,
      paddingX: 0,
      pointerEvents: 'none',
      _groupHover: {
        background: 'wl-blue.royal',
        color: 'white',
        filter: 'drop-shadow(0 0 16px rgba(0, 0, 0, .05))',
        transition: 'all ease .3s',
      },
    },
    image: {
      width: 'full',
      height: { base: 'full', laptop: 216 },
      maxWidth: [24, null, 48, null, 'none'],
      position: 'relative',
      pointerEvents: 'none',
      '.gatsby-image-wrapper': {
        borderRadius: {
          base: '0rem 1rem 1rem 0rem',
          laptop: '0rem 0rem 1rem 1rem',
        },
      },
    },
    color: {
      position: 'absolute',
      inset: 0,
      background: '#BC9075',
      opacity: 0,
      mixBlendMode: 'soft-light',
      transition: 'all ease .2s',
      zIndex: 1,
      _groupHover: {
        opacity: '25%',
        transition: 'all ease .2s',
      },
      borderRadius: {
        base: '0rem 1rem 1rem 0rem',
        laptop: '0rem 0rem 1rem 1rem',
      },
    },
  },
  variants: {},
  sizes: {
    xs: {
      card: {
        flexDirection: 'row',
        maxWidth: 696,
        minHeight: 120,
        maxHeight: 144,
        textAlign: 'left',
      },
      content: {
        padding: '16px 8px 4px 16px',
      },
      action: {
        display: 'none',
      },
      heading: {
        marginBottom: 1,
        fontSize: 'md',
      },
      image: {
        maxW: [24, null, 48],
        '.gatsby-image-wrapper': {
          borderRadius: '0rem 1rem 1rem 0rem',
        },
      },
      color: {
        borderRadius: 'none 2xl 2xl none',
      },
      overline: {
        display: 'none',
      },
    },
    sm: {
      card: {
        maxWidth: 264,
        height: 'auto', // can't institute height until no of content lines limited
      },
      content: {
        alignItems: 'center',
      },
      action: {
        width: 224,
      },
      image: {
        height: 203,
        '.gatsby-image-wrapper': {
          borderRadius: '0rem 0rem 1rem 1rem',
        },
      },
      color: {
        borderRadius: 'none none 2xl 2xl',
      },
    },
    md: {
      card: {
        maxWidth: 314,
        // height: 450,  can't institute height until copy and no/lines limited in contentful
        height: 'auto',
      },
      overline: {
        display: 'block',
      },
      content: {
        alignItems: 'center',
      },
      action: {
        width: 234,
      },
      image: {
        height: 210,
        '.gatsby-image-wrapper': {
          borderRadius: '0rem 0rem 1rem 1rem',
        },
      },
      color: {
        borderRadius: 'none none 2xl 2xl',
      },
    },
    lg: {
      card: {
        maxWidth: 360,
        height: 550,
      },
      overline: {
        display: 'block',
      },
      content: {
        alignItems: 'center',
      },
      action: {
        width: 206,
      },
      image: {
        height: 216,
        '.gatsby-image-wrapper': {
          borderRadius: '0rem 0rem 1rem 1rem',
        },
      },
      color: {
        borderRadius: 'none none 2xl 2xl',
      },
    },
  },
  defaultProps: {},
});

export default CardV2;
