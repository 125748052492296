type StyleProps = {
  color: string;
};

const FeatureBannerHeadline = {
  parts: ['container'],
  baseStyle: ({ color }: StyleProps) => ({
    container: {
      backgroundColor: color || 'blue.600',
      maxWidth: { lg: '360px' },
      padding: 8,
      color: 'white',
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default FeatureBannerHeadline;
