import React from 'react';

import UserJourneyContext from './context';

export const useUserJourney = () => {
  const data = React.useContext(UserJourneyContext);
  return data.entries;
};

export const useSessionId = () => {
  const data = React.useContext(UserJourneyContext);
  return data.sessionId;
};

export const useUtmParams = () => {
  const data = React.useContext(UserJourneyContext);
  return data.utmParams;
};
