const isBrowser = () => typeof window !== 'undefined';

const isLegacyBrowser = () => {
  // Easy way to detect IE, `documentMode` is an MSIE-unique property
  return isBrowser() && !!(window.document as any)['documentMode'];
};

const getViewportHeight = (): number => {
  if (!isBrowser()) {
    return 0;
  }
  return window.innerHeight;
};

const getElementVerticalCoordinate = (element: HTMLElement | null): number => {
  if (!isBrowser()) {
    return 0;
  }
  return (element?.getBoundingClientRect().top ?? 0) + window.scrollY;
};

export {
  getViewportHeight,
  getElementVerticalCoordinate,
  isBrowser,
  isLegacyBrowser,
};
