const QuoteCardV2 = {
  parts: ['container', 'frame', 'overlay'],
  baseStyle: {
    container: {
      position: 'relative',
      zIndex: 1,
      height: '320px',
      width: 'full',
      minWidth: '310px',
      maxWidth: { base: '336px', md: '568px', lg: '744px' },
      textAlign: 'center',
    },
    overlay: {
      position: 'relative',
      paddingTop: { base: '72px', md: '64px' },
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundColor: 'transparent',
      [`.richTextDiv`]: {
        minHeight: '50px',
        fontFamily: 'Inter',
        fontSize: { base: '18px', lg: '22px' },
        lineHeight: { base: '26px' },
        fontWeight: 200,
        color: '#747474',
        b: {
          fontSize: { base: '14px', lg: '15px' },
          color: '#003366',
          lineHeight: { base: '20px' },
        },
        i: {
          fontSize: { base: '12px' },
          lineHeight: { base: '18px' },
        },
      },
    },
  },
};

export default QuoteCardV2;
