const ReturnToTop = {
  parts: ['container', 'icon', 'iconButton'],
  baseStyle: () => ({
    container: {
      transition: 'all 1s ease',
      position: 'fixed',
      bottom: '1.5rem',
      right: '1.5rem',
      zIndex: 9999,
    },
    iconButton: {
      background: 'gray.200',
      color: 'blue.700',
      fontSize: '1.75rem',
      border: '2px solid',
      borderColor: 'gray.200',
      lineHeight: '1.75rem',
      borderRadius: '100%',
      boxShadow: '0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,0.22)',
      _hover: {
        background: 'blue.700',
        borderColor: 'blue.700',
        color: 'white',
      },
    },
    icon: {
      fontSize: '1.75rem',
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default ReturnToTop;
