const Icon = {
  baseStyle: {
    '.background': {
      fill: 'currentColor',
    },
    '.accent': {
      fill: 'white',
    },
  },
  variants: {
    darkIconNoBackground: {
      '.background': {
        fill: 'transparent',
      },
      '.accent': {
        fill: 'currentColor',
      },

      _hover: {},
    },
    lightBlueHover: {
      '.background': {
        fill: 'blue.200',
      },
      '.accent': {
        fill: 'blue.800',
      },

      _hover: {
        '.background': {
          fill: 'blue.400',
        },
      },
    },
  },
};

export default Icon;
