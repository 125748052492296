const getBackgroundColor = (checked: boolean, disabled: boolean) => {
  if (disabled) {
    return checked ? 'gray.700' : 'gray.200';
  } else {
    return checked ? 'blue.800' : 'white';
  }
};
const getLabelColor = (checked: boolean, disabled: boolean) => {
  if (disabled) {
    return undefined;
  }
  return checked ? 'white' : undefined;
};

const TextCheckbox = {
  parts: ['box', 'label'],
  baseStyle: ({ checked, disabled }: any) => ({
    box: {
      backgroundColor: getBackgroundColor(checked, disabled),
      border: 1,
      transition: 'background-color 200ms ease, box-shadow 200ms ease',
      borderStyle: 'solid',
      borderColor: 'gray.400',
      borderRadius: 4,
      cursor: disabled ? undefined : 'pointer',
      paddingX: 4,
      paddingY: 2,
      _hover: {
        boxShadow: '0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)',
      },
    },
    label: {
      color: getLabelColor(checked, disabled),
      cursor: disabled ? undefined : 'pointer',
      margin: 0,
    },
  }),
};

export default TextCheckbox;
