const Banner = {
  parts: ['container', 'frameContainer', 'frame', 'label'],
  baseStyle: ({ size }: { size: 'small' | 'large' }) => ({
    container: {
      width: 'full'
    },
    frameContainer: {
      overflow: 'hidden',
      paddingBottom: '56.25%',
      position: 'relative',
      height: 0,
      width: 'full'
    },
    frame: {
      left: '50%',
      top: '50%',
      position: 'absolute',
      width: '100%',
      maxWidth: size === 'small' ? '200px' : '850px',
      height: '100%',
      maxHeight: size === 'small' ? '136' : '480',
      transform: 'auto',
      translateX: '-50%',
      translateY: '-50%'
    },
    label: {}
  })
};

export default Banner;
