const Link = {
  baseStyle: ({ colorScheme = 'blue' }) => ({
    color: `${colorScheme}.800`,
    _hover: {
      color: `${colorScheme}.700`,
    },
  }),
  variants: {
    plain: (props: any) => ({
      textDecoration: null,
      _hover: {
        textDecoration: null,
      },
    }),
    underline: (props: any) => ({
      textDecoration: 'underline 0.1rem',
      _hover: {
        textDecorationThickness: '0.15rem',
      },
    }),
  },
  defaultProps: {
    variant: 'underline',
  },
};

export default Link;
