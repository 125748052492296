import { extendTheme } from '@chakra-ui/react';

import WLCallout from '../../design-library/Callout';
import { CalloutProps } from '../../design-library/Callout/Callout';

type CalloutColorScheme = NonNullable<
  React.ComponentProps<typeof WLCallout>['colorScheme']
>;
type CalloutVariant = NonNullable<
  React.ComponentProps<typeof WLCallout>['variant']
>;

const BackgroundColors: Partial<Record<CalloutColorScheme, string>> = {
  darkblue: 'wl-blue.royal',
  lightblue: 'wl-blue.sky',
};

const AccentColors: Partial<Record<CalloutColorScheme, string>> = {
  darkblue: 'wl-blue.sky',
  lightblue: 'wl-blue.navy',
};

const getBgColor = (
  colorScheme: CalloutColorScheme = 'darkblue',
  variant: CalloutVariant = 'primary',
): string | undefined => {
  return variant === 'primary' || variant === 'search' || variant === 'image'
    ? BackgroundColors[colorScheme]
    : undefined;
};

const getTextColor = (
  colorScheme: CalloutColorScheme = 'darkblue',
  variant: CalloutVariant = 'primary',
): string | undefined => {
  return variant === 'primary' || variant === 'search' || variant === 'image'
    ? AccentColors[colorScheme]
    : BackgroundColors[colorScheme];
};

const Callout = extendTheme({
  parts: [
    'callout',
    'calloutHeader',
    'content',
    'copy',
    'header',
    'overline',
    'columnTwo',
    'actionGroup',
    'image',
  ],
  baseStyle: {
    callout: {
      minHeight: '383px',
      paddingY: { base: '18px', lg: 0 },
      paddingX: '42px',
      borderRadius: { base: '16px', lg: 'none' },
      position: 'relative',
      [`h1, h2, h3, h4, h5, h6`]: {
        margin: 0,
      },
    },
    content: {},
  },
  variants: {
    primary: ({ colorScheme }: CalloutProps) => ({
      callout: {
        width: { base: 'auto', '2xl': 'container.2xl' },
        justifyContent: { base: 'flex-start', lg: 'center' },
        paddingX: { base: '20px', md: '40px', lg: '78px', xl: '156px' },
        paddingY: { base: '32px', lg: '64px', xl: '80px' },
        background: getBgColor(colorScheme, 'primary'),

        color: getTextColor(colorScheme, 'primary'),
        [`h1, h2, h3, h4, h5, h6`]: {
          textTransform: 'capitalize',
          color: getTextColor(colorScheme, 'primary'),
          fontSize: { tablet: '48px', '2xl': '56px' },
        },
      },
      content: {
        gap: { base: 3, lg: 'unset' },
      },
      header: {
        justifyContent: { base: 'center', lg: 'flex-start' },
        flexBasis: '50%',
        gap: { base: 5, lg: 3 },
        alignItems: { base: 'center', lg: 'flex-start' },
        textAlign: { base: 'center', lg: 'left' },
      },

      columnTwo: {
        justifyContent: 'center',
        flexBasis: '50%',
        gap: 5,
        alignItems: { base: 'center', lg: 'flex-start' },
        textAlign: { base: 'center', lg: 'left' },
      },
      heading: {},
      actionGroup: {
        width: 'full',
        justifyContent: 'flex-start',
      },
    }),
    image: ({ colorScheme }: CalloutProps) => ({
      callout: {
        justifyContent: { lg: 'flex-start' },
        flexDirection: { base: 'column', lg: 'row' },
        alignItems: 'center',
        minHeight: { lg: '383px' },
        paddingX: { base: '20px', md: '40px', xl: '156px' },
        paddingY: { base: '32px', md: '64px', xl: '80px' },
        background: getBgColor(colorScheme, 'image'),
        bgGradient:
          colorScheme === `lightblue` &&
          `linear(to-b, ${getBgColor(colorScheme, 'image')}, 0%,  #D2E6F8)`,
        color: getTextColor(colorScheme, 'image'),
        [`h1, h2, h3, h4, h5, h6`]: {
          textTransform: 'capitalize',
          fontSize: { tablet: '40px' },
        },
      },
      content: {
        zIndex: { base: 1, lg: 'unset' },
        flexDirection: { base: 'column' },
        alignItems: 'center',
        flexBasis: { lg: '70%' },
      },
      header: {
        flexDirection: { base: 'column' },
        alignItems: 'center',
        textAlign: 'center',
      },
      columnTwo: {
        flexDirection: { base: 'column' },
        alignItems: 'center',
        textAlign: 'center',
      },
      copy: {
        display: { base: 'none', lg: 'block' },
      },
      actionGroup: {
        position: { base: 'absolute', lg: 'static' },
        margin: 0,
        bottom: { base: '40px', lg: 'unset' },
        left: { base: '42px', sm: 'unset' },
        right: { base: '42px', sm: 'unset' },
      },
      image: {
        position: { base: 'static', lg: 'absolute' },
        paddingBottom: { base: '64px', lg: 'unset' },
        right: { lg: 42 },
        bottom: 0,
      },
    }),
  },
  sizes: {
    xs: {},
    sm: {},
    md: {},
    lg: {},
  },
  defaultProps: {
    variant: 'primary',
    colorScheme: 'lightblue',
  },
});

export default Callout;
