export { default } from './Divider';

export enum DividerDirection {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum DividerColorScheme {
  STANDARD = 'standard',
  GRAY = 'gray',
  BLUE = 'blue',
  MIXED = 'mixed',
  WHITE = 'white',
}
