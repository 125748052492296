const TermCard = {
  parts: ['container', 'header', 'content', 'control'],
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      border: '4px solid',
      borderColor: 'blue.700',
      boxShadow: 'lg',
      minHeight: '200px',
      width: '300px',
      transition: 'box-shadow 200ms ease',
      _hover: {
        boxShadow: '0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%)',
        svg: {
          backgroundColor: 'blue.50',
        },
      },
    },
    header: {
      backgroundColor: 'blue.700',
      padding: '1rem',
      color: 'white',
    },
    content: {
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      height: 'full',
    },
    control: {
      color: 'blue.500',
      fontSize: '3rem',
      padding: 1,
      transition: 'background-color 250ms ease',
    },
  },
};

export default TermCard;
