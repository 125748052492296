const Input = {
  variants: {
    flushed: {
      field: {
        background: 'blue.50',
        paddingX: '2',
        paddingY: '4',
        borderColor: 'blue.300',
      },
    },
    filled: {
      field: {
        background: 'white',
        borderColor: 'gray.100',
        fontSize: '.75rem',
        _focus: {
          background: 'white',
        },
      },
    },
    unstyled: {
      field: {
        borderRadius: 'none',
      },
    },
    primary: {
      field: {
        background: 'white',
        border: '1px',
        borderRadius: '8px',
        borderColor: '#DBD9D7',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#797877',
      },
    },
  },
  // TODO: these will be modified in the future
  // https://chakra-ui.com/docs/theming/theme#spacing
  sizes: {
    lg: {
      borderRadius: 'none',
    },
    md: {
      borderRadius: 'none',
    },
    sm: {
      borderRadius: 'none',
    },
    xs: {
      borderRadius: 'none',
    },
  },
};

export default Input;
