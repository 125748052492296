import { extendTheme } from '@chakra-ui/react';

import Accordion from './components/accordion';
import ActionableGroupedLinks from './components/actionable-grouped-links';
import ActionableImage from './components/actionable-image';
import Banner from './components/banner';
import Blockquote from './components/blockquote';
import Breadcrumb from './components/breadcrumb';
import Button from './components/button';
import Callout from './components/callout';
import Caption from './components/caption';
import AvatarCard from './components/cards/avatar-card';
import BioCard from './components/cards/bio-card';
import Card from './components/cards/card';
import CardV2 from './components/cards/cardv2';
import ChapterCard from './components/cards/chapter-card';
import EmployeeCard from './components/cards/employee-card';
import FeatureCard from './components/cards/feature-card';
import HeaderCard from './components/cards/header-card';
import IconCard from './components/cards/icon-card';
import LiCalcCard from './components/cards/licalc-card';
import LinksCard from './components/cards/links-card';
import PromoCard from './components/cards/promo-card';
import QuoteCard from './components/cards/quote-card';
import QuoteCardV2 from './components/cards/quote-cardv2';
import TermCard from './components/cards/term-card';
import Checkbox from './components/checkbox';
import ContactActionBox from './components/contact-action-box';
import ContentWarning from './components/content-warning';
import DecorativeText from './components/decorative-text';
import DetailsBlock from './components/details-block';
import Divider from './components/divider';
import EmbeddedVideo from './components/embedded-video';
import Feature from './components/feature';
import FeatureBanner from './components/feature-banner';
import FeatureBannerHeadline from './components/feature-banner-headline';
import FeaturedLink from './components/featured-link';
import Footer from './components/footer';
import Form from './components/form';
import FormLabel from './components/form-label';
import FormStatusFeedback from './components/form-status-feedback';
import Frame from './components/frame';
import FramedImage from './components/framed-image';
import GroupBox from './components/group-box';
import Heading from './components/heading';
import HeadingV2 from './components/headingV2/headingV2';
import HeadlineDivider from './components/headline-divider';
import HeadlineDividerV2 from './components/headline-dividerV2';
import Icon from './components/icon';
import ImageStrip from './components/image-strip';
import Input from './components/input';
import Link from './components/link';
import List from './components/list';
import NumberBadge from './components/number-badge';
import Overline from './components/overline';
import PricingFeature from './components/pricing-feature';
import Product from './components/product';
import Radio from './components/radio';
import ReturnToTop from './components/return-to-top';
import Select from './components/select';
import SplitFeature from './components/split-feature';
import Statistic from './components/statistic';
import SubjectBlock from './components/subject-block';
import Subtitle from './components/subtitle';
import Table from './components/table';
import Tabs from './components/tabs';
import Text from './components/text';
import TextCheckbox from './components/text-checkbox';
import Textarea from './components/textarea';
import TextV2 from './components/textV2';

const theme = extendTheme({
  styles: {
    global: {
      'h1, h2, h3, h4, h5, h6': {
        // color: 'wl-blue.navy',
        wordSpacing: '.075em',
        letterSpacing: '-.025em',
        fontWeight: '700',
      },
      h1: {
        sup: {
          fontSize: '16px',
          top: '-5px',
        },
      },
      h2: {
        sup: {
          fontSize: '14px',
          top: '-6px',
        },
      },
      h3: {
        sup: {
          fontSize: '12px',
          top: '-7px',
        },
      },
    },
  },
  breakpoints: {
    sm: '480px',
    md: '782px',
    tablet: '840px',
    laptop: '1024px',
    lg: '1150px',
    xl: '1408px',
    '2xl': '1536px',
  },
  config: {
    cssVarPrefix: 'wl',
  },
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'portadatext, portada-text, serif',
    mono: 'Menlo, monospace',
  },
  heading: {
    letterSpacing: '.025em',
    wordSpacing: '.075em',
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shortest: 1.25,
    shorter: 1.375,
    short: 1.625,
    base: 1.75,
    tall: 2,
    taller: 2.5,
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.125em',
  },
  fontSizes: {
    '9xl': '80rem',
    '8xl': '64rem',
    '7xl': '48px',
    '6xl': '40px',
    '5xl': '32px',
    '4xl': '28px',
    '3xl': '24px',
    '2xl': '22px',
    xl: '20px',
    lg: '18px',
    md: '16px',
    sm: '14px',
    xs: '12px',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  zIndices: {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  colors: {
    'wl-lightBlue': {
      hex: '#69c',
      rgb: 'rgb(102, 153, 204)',
      rgb10: 'rgba(239, 245, 250)',
      rgb20: 'rgba(224, 235, 245)',
      rgb30: 'rgba(209, 224, 240)',
      rgb40: 'rgba(194, 214, 235)',
      rgb50: 'rgba(178, 153, 204)',
      rgb60: 'rgba(163, 194, 224)',
      rgb70: 'rgba(148, 183, 219)',
      rgb80: 'rgba(133, 173, 214)',
      rgb90: 'rgba(117, 163, 209)',
      // TODO: Consider using MUI's colorManipulator. -NE 20220316
      rgba0: 'rgba(102, 153, 204, 0)',
      rgba10: 'rgba(102, 153, 204, .1)',
      rgba20: 'rgba(102, 153, 204, .2)',
      rgba30: 'rgba(102, 153, 204, .3)',
      rgba40: 'rgba(102, 153, 204, .4)',
      rgba50: 'rgba(102, 153, 204, .5)',
      rgba60: 'rgba(102, 153, 204, .6)',
      rgba70: 'rgba(102, 153, 204, .7)',
      rgba80: 'rgba(102, 153, 204, .8)',
      rgba90: 'rgba(102, 153, 204, .9)',
      rgba100: 'rgba(102, 153, 204, 1)',
      rgba110: 'rgba(181, 204, 225, 1)', // TW - added for homepage hero gradient - redesign
    },
    'wl-blue': {
      royal: '#00549F',
      navy: '#003366',
      midnight: '#001F3C',
      sky: '#B5D5F4',
      'sky-80': '#C3DDF6',
      'sky-60': '#D2E6F8',
      'sky-40': '#E1EEFB',
      'sky-20': '#F0F7FD',
    },
    'wl-darkBlue': {
      hex: '#036',
      rgb: 'rgb(0, 51, 102)',
      // TODO: Consider using MUI's colorManipulator. -NE 20220316
      rgba0: 'rgba(0, 51, 102, 0)',
      rgba10: 'rgba(0, 51, 102, .1)',
      rgba20: 'rgba(0, 51, 102, .2)',
      rgba30: 'rgba(0, 51, 102, .3)',
      rgba40: 'rgba(0, 51, 102, .4)',
      rgba50: 'rgba(0, 51, 102, .5)',
      rgba60: 'rgba(0, 51, 102, .6)',
      rgba70: 'rgba(0, 51, 102, .7)',
      rgba80: 'rgba(0, 51, 102, .8)',
      rgba90: 'rgba(0, 51, 102, .9)',
      rgba100: 'rgba(0, 51, 102, 1)',
      rgba110: 'rgba(0, 84, 159, 1)', // added for darker button in redesign
    },
    white: '#fff',
    gray: {
      0: '#3D3C3C',
      40: '#B7B5B3', // redesign -used in some form elements
      50: '#F5F5F5',
      70: '#747474', // gray for overline style
      100: '#EEEEEE',
      200: '#E0E0E0',
      300: '#BDBDBD',
      400: '#9E9E9E',
      500: '#757575',
      600: '#616161',
      700: '#424242',
      800: '#212121',
      900: '#101010',
      1000: '#B7B5B3', // redesign -used in some form elements
      1001: '#797877', // redesign - Last Breadcrumb
      1002: '#3D3C3C', // new default gray for text
      1003: '#181817', // charcoal for decorative text
    },
    red: {
      50: '#FFE5EE',
      100: '#FFB8CF',
      200: '#FF8AB1',
      300: '#FF5C92',
      400: '#FF2E74',
      500: '#FF0055',
      600: '#CC0044',
      700: '#990033',
      800: '#660022',
      900: '#330011',
      1000: '#EA002A', // for redesign
      1001: '#D52B1E', // for redesign - HeadlineDividerV2
    },
    blue: {
      50: '#e8eff8',
      100: '#c3d9f0',
      200: '#aed0f2',
      300: '#79a6d2',
      400: '#558cc5',
      500: '#3b73ab',
      600: '#0072CE',
      700: '#004993',
      800: '#003366',
      900: '#0e263c',
      1000: '#31C0D3', // for card overlay in redesign
    },
    yellow: {
      50: '#FFFCE0',
      100: '#FFFACC',
      200: '#FFF499',
      300: '#FFEC66',
      400: '#FFE43F',
      500: '#ffd700',
      600: '#DBB500',
      700: '#B79400',
      800: '#937500',
      900: '#7A5F00',
    },
  },
  components: {
    Accordion,
    Blockquote,
    Breadcrumb,
    Button,
    Caption,
    DecorativeText,
    Heading,
    HeadingV2,
    Link,
    List,
    Subtitle,
    Text,
    TextV2,
    Textarea,
    Select,
    Input,
    Form,
    FormLabel,
    Checkbox,
    Table,
    Tabs,
    Radio,

    //Custom Components
    ActionableGroupedLinks,
    ActionableImage,
    FeaturedLink,
    HeadlineDivider,
    HeadlineDividerV2,
    Card,
    CardV2,
    Callout,
    ChapterCard,
    DetailsBlock,
    Divider,
    Feature,
    EmployeeCard,
    IconCard,
    ImageStrip,
    FeatureCard,
    Footer,
    FramedImage,
    Frame,
    BioCard,
    SubjectBlock,
    TermCard,
    GroupBox,
    ContentWarning,
    Icon,
    SplitFeature,
    Statistic,
    TextCheckbox,
    ReturnToTop,
    LinksCard,
    HeaderCard,
    ContactActionBox,
    Product,
    LiCalcCard,
    PromoCard,
    PricingFeature,
    FeatureBanner,
    FeatureBannerHeadline,
    Banner,
    AvatarCard,
    Overline,
    QuoteCard,
    QuoteCardV2,
    EmbeddedVideo,
    NumberBadge,
    FormStatusFeedback,
  },
  sizes: {
    container: {
      sm: '480px',
      md: '782px',
      lg: '1150px',
      xl: '1408px',
      '2xl': '1536px',
    },
  },
});

export default theme;
