const Product = {
  parts: [
    'product',
    'content',
    'color',
    'decor',
    'shadow',
    'action',
    'imgBox',
    'imgWrapper',
    'copy',
  ],
  baseStyle: {
    product: {
      position: 'relative',
      outlineStyle: 'none',
      width: '100%',
    },
    content: {
      outlineStyle: 'none',
    },
    decor: {
      position: 'absolute',
      width: '100%',
      height: '69px',
      top: '69px',
      background:
        'linear-gradient(180deg, rgba(244,241, 239, 10) 0%, rgba(255, 255, 255, 0) 100%);',
      zIndex: '-1',
      borderTop: '1px solid #DBD9D7',
      // opacity: { base: '100%', md: '0%' }
    },
    gradientLeft: {
      position: 'absolute',
      top: '-1px',
      width: '168px',
      height: '85px',
      background:
        'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
    },
    gradientRight: {
      position: 'absolute',
      top: '-1px',
      right: '0',
      width: '168px',
      height: '85px',
      background:
        'linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
    },
    shadow: {
      position: 'absolute',
      width: { base: '138px', md: '168px' },
      height: { base: '138px', md: '168px' },
      margin: '0 auto',
      borderRadius: '100%',
      backgroundColor: 'white',
      filter: 'drop-shadow(0 0 16px rgba(0, 0, 0, .1));',
      opacity: '100%',
      zIndex: '-1',
      transition: 'all .3s ease-out',
      _groupHover: {
        opacity: '100%',
        transform: 'scale(1.2);',
      },
    },
    imgBox: {
      position: 'relative',
      borderRadius: '100%',
      width: { base: '138px', md: '168px' },
      height: { base: '138px', md: '168px' },
      overflow: 'hidden',
    },
    imgWrapper: {
      borderRadius: '100%',
      overflow: 'hidden',
      transition: 'opacity .25s linear, transform .2s ease-out;',
      _groupHover: {
        transform: 'scale(1.15);',
        borderRadius: '100%',
      },
      img: {
        borderRadius: '100%',
      },
    },
    color: {
      position: 'absolute',
      borderRadius: '100%',
      width: '100%',
      height: '100%',
      backgroundColor: '#BC9075',
      mixBlendMode: 'soft-light',
      opacity: '0%',
      transition: 'all .2s ease-out;',
      zIndex: 1,
      pointerEvents: 'none',
      _groupHover: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        opacity: '25%',
        transition: 'all .2s ease-out;',
      },
    },
    action: {
      padding: 0,
      h3: {
        margin: '28px 0px 16px 0px',
        fontSize: ['xl', '2xl'],
        _groupHover: {
          color: 'red',
        },
        span: {
          display: 'inline-block',
          padding: '0',
          transition: 'all ease-out .2s;',
          _groupHover: {
            transform: 'translateX(4px);',
          },
        },
      },
    },
    copy: {
      fontFamily: 'Inter',
      fontSize: '15px',
      lineHeight: '22px',
      margin: 0,
      color: '#3D3C3C',
      textAlign: 'center',
      maxWidth: { base: '336px', sm: '228px' },
    },
  },
  sizes: {
    sm: {},
    md: {},
    lg: {},
  },
  variants: {
    primary: {},
    link: {
      product: {
        _hover: {
          cursor: 'pointer',
        },
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'primary',
  },
};

export default Product;
