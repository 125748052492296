const Textarea = {
  variants: {
    flushed: {
      background: 'blue.50',
      paddingX: '2',
      paddingY: '4',
      borderColor: 'blue.300',
    },
    filled: {
      background: 'white',
      borderColor: 'gray.100',
      fontSize: '.75rem',
      _focus: {
        background: 'white',
      },
    },
  },
  sizes: {
    lg: {
      borderRadius: 'none',
    },
    md: {
      borderRadius: 'none',
    },
    sm: {
      borderRadius: 'none',
    },
    xs: {
      borderRadius: 'none',
    },
  },
};

export default Textarea;
