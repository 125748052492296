const form = {
  baseStyle: {
    marginTop: 1,
    helperText: {
      color: `gray.800`,
    },
    requiredIndicator: {
      color: 'wl-blue.navy',
    },
  },

  variants: {
    light: {
      helperText: {
        color: 'white',
        marginTop: 1,
      },
      requiredIndicator: {
        color: 'white',
      },
      label: {
        color: 'white',
      },
    },
    primary: {},
  },
};

export default form;
