const FeatureBanner = {
  parts: ['container', 'image', 'leftFeature', 'rightFeature'],
  baseStyle: {
    container: {
      position: 'relative',
      display: { base: 'flex', lg: 'block' },
      flexDirection: 'column',
    },
    image: {
      width: 'full',
      height: 'full',
    },
    leftFeature: {
      display: { lg: 'flex' },
      alignItems: { lg: 'center' },
      position: { lg: 'absolute' },
      top: { lg: 0 },
      bottom: { lg: 0 },
      left: { lg: '5%' },
      margin: { lg: 'auto' },
      zIndex: 1,
    },
    rightFeature: {
      display: { lg: 'flex' },
      alignItems: { lg: 'center' },
      position: { lg: 'absolute' },
      top: { lg: 0 },
      bottom: { lg: 0 },
      right: { lg: '5%' },
      margin: { lg: 'auto' },
      zIndex: 1,
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default FeatureBanner;
