import type { TextProps } from '@chakra-ui/react';

const Subtitle = {
  baseStyle: (props: any) => ({
    // Add margin to end of plain paragraphs
    marginBlockEnd: props.as === 'p' ? 2 : undefined,
    fontFamily: 'heading',
    fontWeight: 'bold',
  }),
  variants: {
    primary: ({ colorScheme }: TextProps) => ({
      color: colorScheme ? `${colorScheme}` : `wl-blue.navy`,
    }),
  },
  sizes: {
    sm: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    lg: {
      fontSize: '15px',
      lineHeight: '22px',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Subtitle;
