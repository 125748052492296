import { extendTheme } from '@chakra-ui/react';

const PromoCard = extendTheme({
  parts: ['promoCardsContainer'],
  baseStyle: {
    promoCardsContainer: {
      width: '100%',
      margin: '0px !important',
      background:
        'linear-gradient(180deg, rgba(244,241,239,1) 0%, rgba(244,241,239,0) 250px)',
      borderTop: '1px solid rgba(219, 217, 215, 1)',
    },
    promoCards: {
      marginTop: '-80px',
      marginBottom: '120px',
      padding: '0 12px',
      width: '100%',
      maxWidth: '1152px',
    },
    promoCard__RightFit: {
      position: 'relative',
      overflow: 'hidden',
      flexGrow: '0',
      flexShrink: '1',
      flexBasis: {
        base: 'auto',
        lg: '552px',
      },
      minHeight: '549px',
      margin: '0 auto',
      padding: '0',
      width: '100%',
      maxWidth: '552px',
      backgroundColor: '#011f3c',
      borderRadius: '16px',
      h2: {
        margin: '0',
        padding: '0',
        color: 'white',
        fontSize: {
          base: '32px',
          lg: '48px',
        },
        lineHeight: {
          base: '36px',
          lg: '52px',
        },
        letterSpacing: '-.02em',
        fontWeight: '700',
        textAlign: 'center',
      },
      h3: {
        margin: '0',
        padding: {
          base: '32px 0 18px',
          lg: '48px 0 24px',
        },
        fontFamily: "'Inter', Helvetica, Arial, sans-serif",
        fontSize: {
          base: '13px',
          lg: '14px',
        },
        lineHeight: '20px',
        fontWeight: '900',
        textTransform: 'uppercase',
        color: '#B5D5F4',
        letterSpacing: '.1em',
        wordSpacing: '.125em',
        textAlign: 'center',
      },
    },
    promoCard__LICalc: {
      position: 'relative',
      overflow: 'hidden',
      flexGrow: '0',
      flexShrink: '1',
      flexBasis: {
        base: 'auto',
        lg: '552px',
      },
      margin: '0 auto',
      padding: '0',
      width: '100%',
      maxWidth: '552px',
      backgroundColor: '#00549F',
      borderRadius: '16px',
      h2: {
        margin: '0',
        padding: '0',
        color: 'white',
        fontSize: {
          base: '32px',
          lg: '48px',
        },
        lineHeight: {
          base: '36px',
          lg: '52px',
        },
        letterSpacing: '-.02em',
        fontWeight: '700',
        textAlign: 'center',
      },
      h3: {
        margin: '0',
        padding: {
          base: '32px 0 18px',
          lg: '48px 0 24px',
        },
        fontFamily: "'Inter', Helvetica, Arial, sans-serif",
        fontSize: {
          base: '13px',
          lg: '14px',
        },
        lineHeight: '20px',
        fontWeight: '900',
        textTransform: 'uppercase',
        color: '#B5D5F4',
        letterSpacing: '.1em',
        wordSpacing: '.125em',
        textAlign: 'center',
      },
    },
    buttonGroup__LICalc: {
      position: 'absolute',
      bottom: {
        base: '36px',
        lg: '64px',
      },
      zIndex: '2',
      justifyContent: 'center',
      padding: {
        base: '0 36px',
        lg: '0',
      },
      width: '100%',
    },
    button__Primary__LICalc: {
      display: 'flex',
      whiteSpace: 'nowrap',
      width: '100%',
      height: {
        base: '44px',
        lg: '56px',
      },
      border: '2px solid white',
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#00549F',
      borderRadius: '56px',
      overflow: 'hidden',
      margin: 'auto',
      transition: 'all ease .3s',
      padding: '0 24px',
      background: 'white',
      _hover: {
        color: 'white',
        bg: '#00549F',
        filter: 'drop-shadow(0 0 16px rgba(0, 0, 0, .05))',
        transition: 'all ease .3s',
        cursor: 'pointer',
        border: '2px solid white',
      },
    },
    button__Secondary__LICalc: {
      display: 'flex',
      whiteSpace: 'nowrap',
      width: '100%',
      height: {
        base: '44px',
        lg: '56px',
      },
      border: '2px solid #B5D5F4',
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#00549F',
      borderRadius: '56px',
      overflow: 'hidden',
      margin: 'auto',
      transition: 'all ease .3s',
      padding: '0 24px',
      background: '#B5D5F4',
      _hover: {
        color: 'white',
        bg: '#00549F',
        filter: 'drop-shadow(0 0 16px rgba(0, 0, 0, .05))',
        transition: 'all ease .3s',
        cursor: 'pointer',
        border: '2px solid white',
      },
    },
    imgContainer__LICalc: {
      position: 'relative',
      width: '100%',
      textAlign: 'center',
      _before: {
        content: '""',
        position: 'absolute',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: '1',
        width: '100%',
        height: '200px',
        background:
          'linear-gradient(0deg, rgba(0,51,102,.5), rgba(0,51,102,0))',
      },
      img: {
        position: 'absolute',
        zIndex: '0',
        margin: '0px auto !important',
        width: 'auto',
        height: '100%',
      },
    },
    promoCard__FindALocal: {
      position: 'relative',
      overflow: 'hidden',
      flexGrow: '0',
      flexShrink: '1',
      flexBasis: {
        base: 'auto',
        lg: '552px',
      },
      margin: {
        base: '24px auto 0 !important',
        md: '0 auto !important',
      },
      padding: {
        base: '32px 20px 36px',
        lg: '48px 64px 64px',
      },
      width: '100%',
      maxWidth: '552px',
      backgroundColor: '#B5D5F4',
      borderRadius: '16px',
      h3: {
        margin: '0',
        padding: {
          base: '0 0 18px',
          lg: '0',
        },
        fontFamily: "'Inter', Helvetica, Arial, sans-serif",
        fontSize: {
          base: '13px',
          lg: '14px',
        },
        lineHeight: '20px',
        fontWeight: '900',
        textTransform: 'uppercase',
        color: '#00549F',
        letterSpacing: '.1em',
        wordSpacing: '.125em',
        textAlign: 'center',
      },
    },
    cardHeadingContainer: {
      flexGrow: '0',
      flexShrink: '0',
      flexBasis: 'auto',
    },
    cardContentContainer: {
      position: 'relative',
      flexGrow: '1',
      flexShrink: '1',
      flexBasis: 'auto',
      margin: '0 !important',
      width: '100%',
      h4: {
        fontSize: {
          base: '32px',
          lg: '48px',
        },
        lineHeight: {
          base: '36px',
          lg: '52px',
        },
        color: '#003366',
        letterSpacing: '-.02em',
        fontWeight: '700',
        textAlign: {
          base: 'center',
          lg: 'left',
        },
        position: {
          base: 'static',
          lg: 'absolute',
        },
        top: {
          base: '0',
          lg: '234px',
        },
        zIndex: '0',
        margin: '0',
        padding: {
          base: '0 0 20px',
          lg: '0',
        },
        transform: {
          base: 'none',
          lg: 'translateY(-50%)',
        },
      },
    },
    cardTabContent: {
      position: {
        base: 'static',
        lg: 'absolute',
      },
      zIndex: '0',
      width: '100%',
      height: '100%',
    },
    cardTabImgContainer: {
      position: {
        base: 'static',
        lg: 'absolute',
      },
      zIndex: '2',
      // opacity: "0",
      overflow: 'hidden',
      margin: '0 auto',
      width: '112px',
      height: '112px',
      background: '#00549F',
      // background: "rgba(108, 153, 196, 1)",
      borderRadius: '56px',
      // transform: "translate(var(--findImageXPos), var(--findImageYPos))",
      transform: {
        base: 'translateY(16px)',
        lg: 'translate(136px, 92px)',
      },
      filter: 'drop-shadow(0 6px 12px rgba(10, 40, 70, .35))',
      img: {
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        //transform: 'translateY(56px)'
      },
    },
    cardTabTextContainer: {
      position: {
        base: 'static',
        lg: 'absolute',
      },
      right: '0',
      zIndex: '1',
      // opacity: "0",
      margin: {
        base: '0 auto 36px',
        lg: '0 auto',
      },
      padding: '24px',
      width: {
        base: '100%',
        lg: '200px',
      },
      background: '#fff',
      borderRadius: '16px',
      // transform: "translate(var(--tabContentTextXPos), var(--tabContentTextYPos))",
      transform: {
        base: 'none',
        lg: 'translate(0, 160px)',
      },
      h5: {
        margin: '0',
        padding: '0 0 4px',
        fontSize: {
          base: '22px',
          lg: '20px',
        },
        lineHeight: '28px',
        fontWeight: 'bold',
        letterSpacing: '-.02em',
        color: 'rgba(0, 51, 102, 1)',
        textAlign: {
          base: 'center',
          lg: 'left',
        },
      },
      p: {
        margin: '0',
        fontSize: '15px',
        lineHeight: '22px',
        color: 'rgba(69, 69, 69, 1)',
        textAlign: {
          base: 'center',
          lg: 'left',
        },
      },
    },
    cardActionContainer: {
      zIndex: '3',
      flexGrow: '0',
      flexShrink: '0',
      flexBasis: 'auto',
      margin: '0 !important',
      width: '100%',
    },
    cardTabs: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '350px',
      width: 'auto',
      backgroundColor: 'wl-blue.sky-60',
      borderRadius: 'calc(44px / 2)',
      margin: '0 auto',
    },
    cardTabsMarker: {
      display: 'block',
      content: '""',
      position: 'absolute',
      zIndex: '0',
      // width: "100px",
      height: '100%',
      backgroundColor: '#00549F',
      borderRadius: 'calc(44px / 2)',
      transition: 'all .3s cubic-bezier(.33, 1, 0.68, 1)',
    },
    cardTabsButton: {
      cursor: 'pointer',
      // position: 'relative',
      //zIndex: '1',
      // margin: '0 4px 0 0',
      //padding: '4px 24px 0',
      height: '44px',
      // textAlign: 'center',
      borderRadius: 'calc(44px / 2)',
      //transition: 'all .2s linear',
      //userSelect: 'none',
      //textDecoration: 'none',
      // alignItems: 'center',
      // _last: {
      //   marginRight: '0'
      // },
      _hover: {
        color: 'white',
        background: 'rgba(0, 84, 159, 100%)',
      },
      // &[aria-selected="true"] {
      //   cursor: default;
      //   color: #fff;
      // }
    },
    cardSearchContainer: {
      position: 'relative',
      paddingTop: '24px',
      input: {
        paddingLeft: '24px',
        paddingRight: '100px',
        width: '100%',
        height: {
          base: '44px',
          lg: '56px',
        },
        fontSize: {
          base: '16px',
          lg: '18px',
        },
        fontWeight: '400',
        background: 'white',
        border: 'none',
        borderRadius: '28px',
      },
    },
    cardSearchButton: {
      padding: '0px',
      cursor: 'pointer',
      position: 'absolute',
      zIndex: '1',
      right: '0',
      width: {
        base: '70px',
        lg: '86px',
      },
      height: {
        base: '44px',
        lg: '56px',
      },
      backgroundColor: '#00549F',

      // border: 2px solid #fff;
      borderRadius: '28px',
      transition:
        'background-color .2s linear, width .3s cubic-bezier(.33, 1, 0.68, 1)',
      _hover: {
        width: '96px',
        backgroundColor: 'rgba(0, 51, 102, 1)',
      },
      svg: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: '20px',
        height: '20px',
        transform: 'translate(-50%, -50%)',
        enableBackground: 'new 0 0 64 64',
      },
    },
  },
});

export default PromoCard;
