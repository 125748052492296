import { isBrowser } from './browser.utils';

const getUtmParameters = () => {
  if (!isBrowser()) {
    return null;
  }
  const params = new URLSearchParams(window.location.search);

  const result: Record<string, string> = {};

  params.forEach((value, name) => {
    if (/^utm_/.test(name) && value) {
      result[name] = value;
    }
  });

  if (Object.keys(result).length === 0) {
    return null;
  }

  return result;
};

export { getUtmParameters };
