const ActionableGroupedLinks = {
  parts: [
    'container',
    'actionSection',
    'actionHeading',
    'linksSection',
    'linkGroup',
    'linkGroupHeading',
    'linkGroupDetail',
  ],
  baseStyle: {
    container: {
      width: 'full',
    },
    actionSection: {
      paddingY: 12,
      paddingX: [4, null, '10%', '25%'],
      backgroundColor: 'blue.800',
      color: 'white',
      width: 'full',
    },
    actionHeading: {
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: 'lg',
    },
    linksSection: {
      paddingY: 8,
      paddingX: [2, null, 4, 16],
      backgroundColor: 'blue.50',
      width: 'full',
    },
    linkGroup: {
      gridTemplateColumns: ['1fr', null, null, '1fr 2fr'],
      width: 'full',
      paddingY: 4,
    },
    linkGroupHeading: {
      fontWeight: 700,
    },
    linkGroupDetail: {},
  },
};

export default ActionableGroupedLinks;
