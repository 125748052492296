import { extendTheme } from '@chakra-ui/react';

const Footer = extendTheme({
  parts: ['footerContainer', 'footerPrimary', 'footerMission', 'footerSecondary', 'footerCopyright'],
  baseStyle: {
    footerContainer: {
			backgroundColor: "#003366"
    },
		footerPrimaryContainer: {
			margin: [0],
			paddingBottom: [24],
			width: "100%",
			background: "linear-gradient(180deg, rgba(2,0,36,.35) 0, rgba(1,31,60,0) 150px)"
		},
		footerLogoContainer: {
			margin: "0 auto !important",
			padding: {
				base: '96px 24px 56px',
				md: '96px 12px 56px',
			},
			width: "100%",
			maxWidth: "1152px",
			textAlign: "left"
		},
		footerPrimary: {
			margin: "0 auto !important",
			padding: {
				base: '0 24px',
				md: '0 12px',
			},
			width: "100%",
			maxWidth: "1152px",
		},
		footerPrimarySiteLinks: {
      marginBottom: [9],
			a: {
				display: "inline-block",
				textAlign: "left",
				div: {
					display: "inline-block",
					textAlign: "left",
					p: {
						display: "inline-block",
						fontFamily: "'Inter', Helvetica, Arial, sans-serif",
						fontSize: "18px !important",
						lineHeight: "32px !important",
						fontWeight: "bold",
						color: "white",
						textAlign: "left"
					}
				}
			}
		},
		footerPrimarySocialLinks: {
      marginBottom: [12],
			a: {
				overflow: "hidden",
				width: "44px",
				height: "44px",
				marginLeft: "16px !important",
				_first: {
					marginLeft: "0 !important"
				}
			}
		},
		footerEmailSignUp: {
      marginBottom: [9],
			h2: {
				marginBottom: [1],
				padding: [0],
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: "bold",
				textTransform: "uppercase",
				color: "#B5D5F4",
				letterSpacing: ".125em",
				wordSpacing: ".125em"
			},
      p: {
        marginBottom: [0],
      },
			a: {
				display: "inline-block",
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				fontSize: "12px",
				lineHeight: "18px",
				fontWeight: "bold",
				color: "white",
				textAlign: "left",
				textDecoration: "none",
				_hover: {
					color: "white",
					textDecoration: "none",
				}
			}
		},
		footerPrimaryContact: {
			marginBottom: [10],
			fontFamily: "'Inter', Helvetica, Arial, sans-serif",
			fontSize: "12px",
			lineHeight: "18px",
			color: "white",
			textAlign: "left",
			h2: {
				margin: [0],
				paddingBottom: [1],
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: "bold",
				textTransform: "uppercase",
				color: "#B5D5F4",
				letterSpacing: ".125em",
				wordSpacing: ".125em",
        _last: {
          paddingTop: [6],
        },
			},
			a: {
				display: "inline-block",
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				fontWeight: "bold",
				color: "white",
				textAlign: "left",
				textDecoration: "none",
				_hover: {
					color: "white",
					textDecoration: "none",
				}
			},
			p: {
				marginBottom: [4],
				_first: {
					a: {
						fontFamily: "'Portada-Text', Georgia, Times New Roman, serif",
						fontSize: "20px",
						lineHeight: "30px",
						fontVariantNumeric: "lining-nums"
					}
				},
        _last: {
          marginBottom: [0],
        },
			}
		},
		footerPrimaryLegalLinks: {
			a: {
				display: "inline-block",
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				fontSize: "16px",
				lineHeight: "30px",
				fontWeight: "bold",
				color: "white",
				textAlign: "left",
				textDecoration: "none",
				_hover: {
					color: "white",
					textDecoration: "none",
				}
			}
		},
		footerSecondary:  {
			margin: "0 !important",
			paddingBottom: [24],
			width: "100%",
			backgroundColor: "#011F3C",
		},
		footerMission: {
			position: "relative",
			margin: "0 auto !important",
			padding: {
				base: '0 24px',
				md: '0 12px',
			},
			width: "100%",
			maxWidth: "1152px",
			textAlign: "center",
			background: {
				base: "linear-gradient(0deg, rgba(0,51,102,.75) 0%, rgba(1,31,60,0) 16%)",
				md: "linear-gradient(0deg, rgba(0,51,102,.75) 0%, rgba(1,31,60,0) 32%)",
			},
		},
		footerMissionGradientLeft: {
			position: "absolute",
			top: "0",
			left: "0",
			zIndex: "0",
			width: {
				base: "30%",
				md: '50%',
			},
			height: "100%",
			background: "linear-gradient(90deg, rgba(1,31,60,1) 24%, rgba(1,31,60,.01) 100%)"
		},
		footerMissionGradientRight: {
			position: "absolute",
			top: "0",
			right: "0",
			zIndex: "0",
			width: {
				base: "30%",
				md: '50%',
			},
			height: "100%",
			background: "linear-gradient(-90deg, rgba(1,31,60,1) 24%, rgba(1,31,60,.01) 100%)"
		},
		footerMissionText: {
			position: "relative",
			zIndex: "1",
			paddingTop: [20],
      paddingBottom: [20],
			color: "#B5D5F4",
			div: {
				maxWidth: '100%',
			},
			h2: {
				margin: [0],
				paddingBottom: "24px",
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: "bold",
				textTransform: "uppercase",
				color: "#B5D5F4",
				letterSpacing: ".125em",
				wordSpacing: ".125em"
			},
			p: {
        margin: [0],
				color: "#B5D5F4",
				fontFamily: "'Portada-Text', Georgia, Times New Roman, serif",
				fontSize: "32px",
				fontWeight: "300",
				lineHeight: "36px"
			}
		},
		footerDisclosuresSection: {
			margin: "0 auto !important",
			padding: {
				base: "56px 24px 0",
				md: "96px 12px 0",
			},
			width: "100%",
			maxWidth: "1152px"
		},
		footerDisclosures:  {
			paddingRight: {
				md: [16],
			},
			h2: {
				marginBottom: [3],
				paddingTop: [12],
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: "bold",
				textTransform: "uppercase",
				color: "#B5D5F4",
				letterSpacing: ".125em",
				wordSpacing: ".125em"
			},
			p: {
				marginBottom: [3],
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				fontSize: "12px",
				lineHeight: "18px",
				color: "#B5D5F4",
				a: {
					fontWeight: "bold",
					color: "white",
					textDecoration: "none",
					_hover: {
						color: "white",
						textDecoration: "underline"
					}
				}
			}
		},
		footerRatingsAndAwardsContainer: {
			flexGrow: "0",
			flexShrink: "0",
			flexBasis: "320px",
			marginBottom: '56px !important',
		},
		footerRatingsAndAwards: {
			padding: "36px",
			width: "100%",
			backgroundColor: "rgba(255, 255, 255, .05)",
			borderRadius: "16px",
			h2: {
				margin: "0",
				padding: "0 12px 0 0",
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				fontSize: "14px",
				lineHeight: "20px",
				fontWeight: "bold",
				textTransform: "uppercase",
				color: "#B5D5F4",
				letterSpacing: ".125em",
				wordSpacing: ".125em"
			},
			a: {
				fontSize: "12px",
				fontWeight: "bold",
				color: "white",
				textDecoration: "none",
				_hover: {
					color: "white",
					textDecoration: "underline"
				}
			},
			p: {
				padding: "24px 0 0",
				fontSize: "12px",
				color: "white",
				textAlign: "center"
			}
		},
		footerFraternalAlliance: {
			p: {
				color: '#B5D5F4',
			},
			'.RichTextAssetBlock': {
				width: '128px',
				height: '128px',
				background: 'white',
				borderRadius: '100%',
			},
		},
		footerCopyrightContainer:  {
			margin: "0 !important",
			width: "100%",
			backgroundColor: "#011125"
		},
		footerCopyright:  {
			margin: "0 auto",
			width: "100%",
			maxWidth: "1152px",
			padding: {
				base: "24px 24px",
				md: "24px 12px",
			},
			p: {
				margin: [0],
				fontFamily: "'Inter', Helvetica, Arial, sans-serif",
				color: "white",
				fontSize: "12px",
				lineHeight: "18px"
			}
		},
  },
});

export default Footer;
