import type { StyleFunctionProps } from '@chakra-ui/theme-tools';

const Accordion = {
  baseStyle: ({ colorScheme }: StyleFunctionProps) => ({
    container: {
      borderTopWidth: 0,
      borderColor: 'inherit',
      _last: {
        borderBottomWidth: 0,
      },
    },
    button: {
      fontSize: '1rem',
      fontWeight: '700',
      background: `${colorScheme}.50`,
      border: 'solid 1px',
      borderColor: 'white',
      _focus: {
        boxShadow: 'outline',
      },
      _hover: {
        background: `${colorScheme}.100`,
      },
      padding: 4,
      color: 'blue.800',
    },
  }),
  variants: {
    table: ({ colorScheme }: StyleFunctionProps) => ({
      container: {
        borderTopWidth: 0,
        borderColor: 'inherit',
        _last: {
          borderBottomWidth: 0,
        },
      },
      button: {
        fontSize: '1rem',
        fontWeight: '900',
        padding: '.25rem 1rem .25rem 1rem',
        background: `${colorScheme}.100`,
        _expanded: {
          background: `${colorScheme}.600`,
          color: 'white',
        },
        _focus: {
          boxShadow: 'outline',
        },
        _hover: {
          background: `${colorScheme}.200`,
        },
      },
      icon: {
        display: 'none',
      },
    }),
  },
  defaultProps: {
    colorScheme: 'blue',
  },
};

export default Accordion;
