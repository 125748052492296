/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './fonts';
import './static/fonts/all.min.css';

import React from 'react';
import { UserJourneyProvider } from './src/components/UserJourney';
import { getUtmParameters } from './src/utils/form.utils';

export const shouldUpdateScroll = () => {

  window.scrollTo({
    top: 0,
  });

  return false;

};

const generateSessionId = () => {
  /*
  Attempt to generate a pseudo-random session ID. We don't take great pains to guarantee uniqueness since this is an
  investigational/marketing analysis tool more than anything. If a more robust solution is needed in the future
  we should investigate the `uuid` library - leaving this out for now to minimize bundle size.
  */
  try {
    if (!window?.crypto?.getRandomValues) {
      return '_';
    }
    const array = new Uint32Array(3);
    window.crypto.getRandomValues(array);
    return array.join('');
  } catch {
    return '_';
  }
};

// TODO Consider extracting `sessionId` and `userJourney` to be stored in Session Storage instead of in-memory
const sessionId = generateSessionId();
const userJourney = [];
const utmParams = getUtmParameters();

export function wrapRootElement({ element }) {
  return (
    <UserJourneyProvider
      sessionId={sessionId}
      entries={userJourney}
      utmParams={utmParams}
    >
      {element}
    </UserJourneyProvider>
  );
}

let dataLayerPush;

export function onRouteUpdate({ location, prevLocation }) {
  userJourney.push({
    page: location.href,
    timestamp: new Date().toISOString(),
  });

  // TODO
  //      Decide if we need to dispatch a specific event to help Google Optimize decide whether to trigger a redirect
  //      We can configure Optimize to use the `gatsby-route-change` event being generated by our GTM plugin, or we
  //      can push a custom one like so: `window.dataLayer.push({ event: "optimize.activate" });`
}
