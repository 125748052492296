import type { ActionableImageProps } from '../../design-library/Image/ActionableImage/ActionableImage';

const getColorScheme = (color = 'orange') => {
  if (color === 'orange') {
    return '#d45d00';
  }
  if (color === 'green') {
    return '#a9c47f';
  }
  if (color === 'blue') {
    return '#003366';
  }
  if (color === 'yellow') {
    return '#e6b31a';
  }
  if (color === 'red') {
    return '#990033';
  }

  return `${color}.700`;
};

const getLightenedColor = (color = 'orange') => {
  if (color === 'orange') {
    return '#ff9033';
  }
  if (color === 'green') {
    return '#c3de99';
  }
  if (color === 'blue') {
    return '#044d95';
  }
  if (color === 'yellow') {
    return '#ffcc33';
  }
  if (color === 'red') {
    return '#bb0033';
  }

  return `${color}.300`;
};
const ActionableImage = {
  parts: [
    'container',
    'titleBox',
    'titleBoxText',
    'overlay',
    'overlayButton',
    'overlayTitle',
    'overlaySubtext',
  ],
  baseStyle: ({ color = 'orange' }: ActionableImageProps) => ({
    container: {
      borderTop: `solid 10px`,
      borderTopColor: getColorScheme(color),
      position: 'relative',
    },
    titleBox: {
      position: 'absolute',
      color: 'white',
      backgroundColor: 'rgba(102, 153, 204, 0.5)',
      width: '100%',
      height: '25%',
      bottom: 0,
      left: 0,
      textAlign: 'center',
      lineHeight: 1.25,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    titleBoxText: {
      textAlign: 'center',
      lineHeight: '1',
      fontSize: '1.75rem',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: '100%',
      left: 0,
      color: 'white',
      overflow: 'hidden',
      transition: 'bottom 0.7s',
      background: getColorScheme(color),
      backgroundImage: `linear-gradient(to bottom, ${getColorScheme(
        color
      )} 60%, ${getLightenedColor(color)})`,
      display: 'flex',
      justifyContent: 'center',
      alighItems: 'center',
      textAlign: 'center',
      _groupHover: {
        bottom: 0,
      },
      _focusWithin: {
        bottom: 0,
      },
    },
    overlayTitle: {
      marginTop: '2rem',
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
    overlaySubtext: {
      fontSize: '.8rem',
      marginBottom: '1rem',
    },
    overlayButton: {
      fontSize: '20px',
      borderRadius: 'full',
      marginX: '.5rem',
    },
  }),
};

export default ActionableImage;
