const HeadlineDivider = {
  parts: ['text', 'container', 'divider'],
  baseStyle: {
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      justifyItems: 'center',
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: '4rem',
    },
    divider: {
      content: '""',
      borderBottomColor: `blue.500`,
      borderBottom: '1px solid',
      width: ['1.5rem', '4.5rem'],
      marginTop: 0,
      marginBottom: 0,
      padding: 0,
      verticalAlign: 'middle',
      marginRight: '1rem',
    },
    text: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.1rem',
      color: 'gray.500',
      letterSpacing: '0.1em',
      textAlign: 'left',
      textTransform: 'uppercase',
      textDecoration: 'none',
      wordBreak: 'normal',
      padding: '0 1.1rem 0 0',
      margin: 0,
    },
  },
  sizes: {},
  variants: {
    short: {
      divider: {
        width: ['1.5rem', '1.5rem'],
      },
    },
    light: {
      divider: {
        color: 'white',
        borderBottomColor: 'white',
      },
      text: {
        color: 'white',
      },
    },
    lightShort: {
      divider: {
        width: ['1.5rem', '1.5rem'],
        color: 'white',
        borderBottomColor: 'white',
      },
      text: {
        color: 'white',
      },
    },
  },
  defaultProps: {},
};

export default HeadlineDivider;
