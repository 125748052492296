//TODO: edit these for 'responsiveness'
const sizes = {
  sm: {
    control: { height: 6, width: 6 },
    label: { fontSize: 'sm' },
    icon: { fontSize: '1rem' },
  },
  md: {
    control: { width: 6, height: 6 },
    label: { fontSize: 'md' },
    icon: { fontSize: '1rem' },
  },
  lg: {
    control: { width: 6, height: 6 },
    label: { fontSize: 'md' },
    icon: { fontSize: '1rem' },
  },
};

const Checkbox = {
  baseStyle: {
    control: {
      bg: 'blue.100',
      borderColor: 'blue.200',
      border: '1px solid',
      borderRadius: 'none',
    },
  },
  variants: {
    claims: {
      control: {
        bg: 'white',
        borderColor: '#D0D0D0',
        borderRadius: '4px',
        boxSize: 5,
        _focusVisible: {
          outlineColor: '#D0D0D0',
          boxShadow: 'grey',
        },
        _checked: {
          bg: 'white',
          borderColor: '#D0D0D0',
          _hover: {
            bg: 'white',
            borderColor: '#D0D0D0',
          },
        },
        _hover: {
          borderColor: '#D0D0D0',
        },
      },
      icon: {
        color: 'grey',
      },
    },
    primary: {
      control: {
        bg: 'white',
        borderColor: '#D0D0D0',
        borderRadius: '4px',
        boxSize: 5,
        _focusVisible: {
          outlineColor: '#D0D0D0',
          boxShadow: 'grey',
        },
        _checked: {
          bg: 'white',
          borderColor: '#D0D0D0',
          _hover: {
            bg: 'white',
            borderColor: '#D0D0D0',
          },
        },
        _hover: {
          borderColor: '#D0D0D0',
        },
      },
      icon: {
        color: 'grey',
      },
    },
  },
  sizes,
};

export default Checkbox;
