const FeaturedLink = {
  parts: ['container', 'image', 'content', 'title', 'linkText'],
  baseStyle: ({ invert }: { invert: boolean }) => ({
    container: {
      flexDirection: ['column', null, invert ? 'row-reverse' : 'row'],
      justifyContent: 'space-around',
      width: 'full',
      alignItems: 'center',
    },
    image: {
      maxWidth: '400px',
    },
    content: {
      height: 'full',
      maxWidth: '400px',
      alignItems: 'start',
    },
    title: {
      textTransform: 'uppercase',
    },
    linkText: {
      alignItems: 'start',
      paddingLeft: 2,
      p: {
        marginBlockEnd: 0,
        fontSize: '14px',
        lineHeight: '18px',
      },
      b: {
        fontSize: '1rem',
        lineHeight: '22px',
      },
    },
  }),
};

export default FeaturedLink;
