import { extendTheme } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';

const Overline = extendTheme(({ colorScheme }) => ({
  baseStyle: {
    display: 'block',
    fontFamily: 'body',
    fontSize: { base: 'xs', md: 'sm' },
    fontWeight: 'black',
    color: '#747474',
    textTransform: 'uppercase',
    lineHeight: 'shorter',
    letterSpacing: 'widest',
    wordSpacing: '0.125em',
    marginBottom: '1.5rem',
  },
  variants: {
    solid: (props: StyleFunctionProps) => ({
      color:
        props.colorScheme === 'white'
          ? 'white'
          : (props.colorScheme = 'royal' ? `wl-blue.royal` : '#747474'),
    }),
  },
  sizes: {
    xs: {
      fontSize: { base: 'xs', md: 'sm' },
    },
  },
  defaultProps: {
    variant: 'solid',
    colorScheme: `#747474`,
    colorMode: 'light',
    size: 'xs',
  },
}));

export default Overline;
