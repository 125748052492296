import type { HeadingProps } from '@chakra-ui/react';

const Heading = {
  variants: {
    primary: ({ colorScheme }: HeadingProps) => ({
      color: colorScheme ? `${colorScheme}.800` : undefined,
    }),
    overline: ({ colorScheme }: HeadingProps) => ({
      color:
        colorScheme === 'lightblue'
          ? `wl-blue.royal`
          : colorScheme === `darkblue`
          ? `white`
          : `gray.1001`,
      fontFamily: 'Inter',
      fontWeight: 'black',
      fontSize: { base: 'xs', md: 'sm' },
      textTransform: 'uppercase',
      lineHeight: 'shorter',
      letterSpacing: 'widest',
      wordSpacing: '0.125em',
    }),
  },
  sizes: {
    // H1
    '2xl': {
      marginBlockEnd: ['0.33rem', '0.5rem', '0.67em', '0.83em'],
    },
    xl: {
      marginBlockEnd: ['0.25rem', '0.4rem', '0.5em', '0.75em'],
    },
    lg: {
      marginBlockEnd: ['0.2rem', '0.3rem', '0.4em', '0.66em'],
    },
    md: {
      marginBlockEnd: ['0.15rem', '0.25rem', '0.3em', '0.5em'],
    },
    sm: {
      marginBlockEnd: ['0.125rem', '0.175rem', '0.2em', '0.33em'],
    },
    // H6
    xs: {
      marginBlockEnd: ['0.1rem', '0.15em', '0.25em'],
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Heading;
