import { extendTheme } from '@chakra-ui/react';

const LiCalcCard = extendTheme({
  parts: ['promoCardsContainer'],
  baseStyle: {
    promoCard__LICalc: {
      position: 'relative',
      maxW: '589px',
      minW: { base: '336px', md: 'unset' },
      overflow: 'hidden',
      flexGrow: '0',
      flexShrink: '1',
      flexBasis: {
        base: 'auto',
        lg: '552px',
      },
      margin: '0 auto',
      padding: '0',
      backgroundColor: '#00549F',
      borderRadius: '16px',
      h2: {
        margin: '0',
        padding: '0',
        color: 'white',
        fontSize: '32px',
        lineHeight: '36px',
        letterSpacing: '-.02em',
        fontWeight: '700',
        textAlign: 'center',
      },
      h3: {
        margin: '0',
        padding: { base: '0px', md: '0 0 18px' },
        fontFamily: "'Inter', Helvetica, Arial, sans-serif",
        fontSize: '13px',

        lineHeight: '20px',
        fontWeight: '900',
        textTransform: 'uppercase',
        color: '#B5D5F4',
        letterSpacing: '.1em',
        wordSpacing: '.125em',
        textAlign: 'center',
      },
    },
    buttonGroup__LICalc: {
      position: { base: 'unset', md: 'absolute' },
      bottom: {
        base: '0px',
        md: '36px',
      },
      zIndex: '2',
      justifyContent: 'center',
      padding: {
        base: '32px 24px 0px 24px',
        md: '0 36px',
      },
      width: '100%',
    },
    button__Primary__LICalc: {
      display: 'flex',
      whiteSpace: 'nowrap',
      width: { base: '200px', md: '100%' },
      height: {
        base: '44px',
      },
      position: { base: 'absolute', md: 'unset' },
      bottom: '56px',
      left: '0px',
      right: '0px',
      zIndex: { base: '1', md: 'unset' },
      border: '2px solid white',
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#00549F',
      borderRadius: '56px',
      overflow: 'hidden',
      margin: 'auto',
      transition: 'all ease .3s',
      padding: '0 24px',
      background: 'white',
      _hover: {
        color: 'white',
        bg: '#00549F',
        filter: 'drop-shadow(0 0 16px rgba(0, 0, 0, .05))',
        transition: 'all ease .3s',
        cursor: 'pointer',
        border: '2px solid white',
      },
    },
    button__Secondary__LICalc: {
      display: 'flex',
      whiteSpace: 'nowrap',
      width: '100%',
      height: {
        base: '44px',
        lg: '56px',
      },
      border: '2px solid #B5D5F4',
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '700',
      color: '#00549F',
      borderRadius: '56px',
      overflow: 'hidden',
      margin: 'auto',
      transition: 'all ease .3s',
      padding: '0 24px',
      background: '#B5D5F4',
      _hover: {
        color: 'white',
        bg: '#00549F',
        filter: 'drop-shadow(0 0 16px rgba(0, 0, 0, .05))',
        transition: 'all ease .3s',
        cursor: 'pointer',
        border: '2px solid white',
      },
    },
    imgContainer__LICalc: {
      position: 'relative',
      width: { base: '100%', md: '50%' },
      height: { base: '50%', md: 'auto' },
      textAlign: 'center',
      right: '0px',

      img: {
        position: 'absolute',
        zIndex: '0',
        margin: '0px auto !important',
        width: 'auto',
        height: '100%',
      },
    },
  },
});

export default LiCalcCard;
