const FormLabel = {
  baseStyle: {
    marginBottom: 'none',
    fontWeight: '700',
  },
  variants: {
    claims: {
      color: '#003366',
      paddingLeft: '8px',
      marginRight: '0px',
    },
    primary: {
      color: '#003366',
      paddingLeft: '8px',
      marginRight: '0px',
    },
  },
};

export default FormLabel;
