const getBackground = (scheme: string) => {
  if (scheme === 'yellow') {
    return `#fed34c`;
  } else if (scheme === 'blue') {
    return `wl-blue.sky`;
  }
  return undefined;
};

const getTextColor = (scheme: string) => {
  // If we support dark backgrounds in the future we would need to handle 'white' text here
  return undefined;
};

const getDividerColor = (scheme: string) => {
  if (scheme === 'yellow') {
    // return 'yellow.600';
    return 'white';
  }
  return 'black';
};

const Frame = {
  parts: ['marquee', 'frame', 'header', 'content', 'disclosures'],
  baseStyle: ({ colorScheme }: any) => ({
    marquee: {
      width: 'full',
      height: '200px',
      background: 'rgba(225,238,251,.5);',
    },
    frame: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: { base: '332px', tablet: '442px', lg: '552px' },
      height: 'auto',
      margin: '0 auto',
      marginTop: { base: '-80px', lg: '-96px', xl: '-160px' },
      padding: 0,
      borderRadius: '16px',
      // bg: getBackground(colorScheme),
      backgroundColor: '#E1EEFB',
      filter: `drop-shadow(0 0 32px rgba(0, 0, 0, .05));`,
      transition: `all .3s ease;`,
    },
    content: {
      width: 'full',
      textAlign: 'center',
      color: `wl-blue.navy`,
      padding: '32px 0 40px 0',
      borderRight: '1px solid #E7E6E5',
      borderBottom: '1px solid #E7E6E5',
      borderLeft: '1px solid #E7E6E5',
      borderRadius: '0 0 16px 16px',
      backgroundColor: 'white',
      h2: {
        fontSize: { base: '32px', tablet: '48px' },
        lineHeight: { base: '36px', tablet: '28px' },
      },
      p: {
        mb: { base: '12px', tablet: '24px' },
        lineHeight: { base: '22px', tablet: '28px' },
      },
      [`p, b`]: {
        fontFamily: 'heading',
        fontSize: { base: '16px', tablet: '24px' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    header: {
      position: 'relative',
      width: { base: 'full', tablet: '442px', lg: '550px' },
      height: { base: 'auto', tablet: 'full' },
      padding: { base: '24px 0px' },
      // borderTop: '1px solid #D4DCE2',
      // borderRight: '1px solid #D4DCE2',
      // borderLeft: '1px solid #D4DCE2',
      // borderRadius: '16px 16px 0 0',
      transition: 'all .3s ease',
      textAlign: 'center',
      h2: {
        padding: 0,
        margin: 0,
        fontSize: { base: '32px', tablet: '48px' },
        transition: 'all .3s ease',
        lineHeight: { base: '36px', tablet: '28px' },
        sup: {
          top: '-6px',
          verticalAlign: 'super',
          position: 'relative',
          transition: 'all .3s ease',
        },
      },
      p: {
        fontFamily: 'heading',
        fontWeight: 'bold',
        color: '#036',
        letterSpacing: '-.025em',
        wordSpacing: '0.075em',
        lineHeight: { tablet: '28px' },
        padding: { base: '0 80px', tablet: '0 140px' },
        margin: { base: '12px 0 0 0', tablet: '24px 0 0 0 ' },
        fontSize: { base: '16px', tablet: '24px' },
        transition: 'all .3s ease',
      },
    },
    disclosures: {
      backgroundColor: 'white',
      margin: '0 0 0 0',
      marginTop: '16px',
      padding: '0 19px',
      fontFamily: 'Inter',
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
      color: '#747474',
      p: {
        fontSize: '12px',
      },
    },
  }),
  sizes: {},
  variants: {
    horizontal: {
      frame: {
        flexDirection: { base: 'column', tablet: 'row' },
        width: 'full',
        maxWidth: '1128px',
        overflow: 'hidden',
        background: '#E1EEFB',
        border: '1px solid #E7E6E5',
        borderRadius: '16px',
        filter: 'drop-shadow(0 8px 12px rgba(0, 0, 0, .025));',
        alignItems: 'center',
      },
      header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: { base: 'full', lg: '40%' },
        height: { tablet: 'full' },
        // borderRadius: { base: '16px 16px 0 0', tablet: '16px 0px 0px 16px' },
        // borderBottom: { base: 'none', tablet: '1px solid #D4DCE2' },
        // borderRight: { base: '1px solid #D4DCE2', tablet: 'none' },
        // _after: {
        //   content: '""',
        //   width: 'full',
        //   height: 'full',
        //   position: 'absolute',
        //   top: { base: '100%', tablet: '50%' },
        //   left: { base: '50%', tablet: '100%' },
        //   transform: {
        //     base: 'translateX(-50%);',
        //     tablet: 'translateY(-50%)',
        //   },
        //   border: {
        //     base: '386px solid transparent',
        //     tablet: '150px solid transparent',
        //   },
        //   borderTop: { base: '40px solid #E1EEFB' },
        //   borderLeft: { tablet: '40px solid #E1EEFB' },
        // },
      },
      content: {
        width: { base: 'full', lg: '60%' },
        height: 'full',
        padding: {
          base: '24px 0px',
          tablet: '40px',
        },
        borderRight: '1px solid #E7E6E5',
        borderBottom: '1px solid #E7E6E5',
        borderLeft: '1px solid #E7E6E5',
        borderRadius: { base: '0 0 16px 16px', tablet: '0 16px 16px 0px' },
        overflow: 'hidden',

        p: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        },
        ul: {
          [`li [class*="decorator"]`]: {
            height: { tablet: '32px !important' },
            width: { tablet: '2px !important' },
            margin: { tablet: '0 16px !important' },
          },
          padding: 0,
          li: {
            flexDirection: { base: 'column', tablet: 'row !important' },
            margin: { tablet: '0 0 16px 0 !important' },
            span: {
              _first: {
                textAlign: { base: 'unset', tablet: 'right' },
                flexBasis: { base: 'unset', tablet: '90px' },
              },
            },
          },
        },
      },
      disclosures: {},
    },
  },
  defaultProps: {},
};

export default Frame;
