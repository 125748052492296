import type { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction } from '@chakra-ui/theme-tools';

const variantMain: PartsStyleFunction<typeof parts> = (props) => {
  const { orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderProp =
    orientation === 'vertical' ? 'borderStart' : 'borderBottom';
  const marginProp = isVertical ? 'marginStart' : 'marginBottom';

  return {
    root: {
      background: 'blue.800',
    },
    tablist: {
      paddingY: '1.5rem',
      border: 'none',
    },
    tabpanel: {
      background: 'white',
    },
    tab: {
      display: ['none', null, 'flex'],
      paddingX: 'none',
      [borderProp]: '2px solid',
      borderColor: 'transparent',
      color: 'gray.300',
      marginX: '1rem',
      [marginProp]: '.25rem',
      _selected: {
        color: 'white',
        [borderProp]: '2px solid',
        borderColor: `white`,
      },
      _hover: {
        color: 'white',
        [borderProp]: '2px solid',
        borderColor: `white`,
      },
      _active: {},
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  };
};

const variantDots: PartsStyleFunction<typeof parts> = () => ({
  tabpanel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    alignContent: 'center',
  },
  tablist: {
    border: 'none',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tab: {
    padding: '1rem',
    marginY: '1rem',
  },
});

const Tabs = {
  variants: {
    dots: variantDots,
    main: variantMain,
  },
};

export default Tabs;
