import type { FramedImageProps } from '../../design-library/Image/FramedImage/FramedImage';

const FramedImage = {
  parts: ['container', 'frame', 'image', 'overlay', 'text'],
  baseStyle: ({ href }: FramedImageProps) => ({
    container: {
      position: 'relative',
      margin: '0 0 24px 24px',
      zIndex: 1,
    },
    frame: {
      position: 'absolute',
      border: '2px solid #ba0c2f',
      height: '100%',
      width: '100%',
      zIndex: '-1',
      transition: '0.5s ease',
      _groupHover: () =>
        href
          ? {
              zIndex: 2,
              top: '0',
              left: '0',
              transform: 'scale(0.8)',
              transition: '0.5s ease',
            }
          : {},
    },
    image: {},
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      opacity: 0,
      transition: '0.5s ease',
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      _groupHover: () =>
        href
          ? {
              opacity: 1,
              zIndex: 4,
            }
          : {},
    },
    text: {
      fontWeight: '700',
      fontSize: '.75rem',
      lineHeight: '.75rem',
      letterSpacing: '0.1em',
      textAlign: 'center',
      textTransform: 'uppercase',
      textDecoration: 'none',
      wordBreak: 'normal',
      color: 'white',
    },
  }),
  sizes: {},
  variants: {
    topLeft: {
      frame: {
        top: '-5%',
        left: '-5%',
      },
    },
    bottomLeft: {
      frame: {
        top: '5%',
        left: '-5%',
      },
    },
    topRight: {
      frame: {
        top: '-5%',
        left: '5%',
      },
    },
    bottomRight: {
      frame: {
        top: '5%',
        left: '5%',
      },
    },
  },
  defaultProps: {
    variant: 'topLeft',
  },
};

export default FramedImage;
