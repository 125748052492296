import type { SelectProps } from '@chakra-ui/react';
import type { PartsStyleFunction } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const variantPrimary: PartsStyleFunction = (props) => {
  return {
    field: {
      border: '1px solid',
      borderRadius: 'none',
      borderColor: mode('blue.200', 'whiteAlpha.50')(props),
      bg: mode('blue.100', 'whiteAlpha.50')(props),
      _hover: {
        bg: mode('blue.200', 'whiteAlpha.100')(props),
      },
    },
    icon: {},
  };
};

const variantSecondary: PartsStyleFunction = (props) => {
  return {
    field: {
      border: '1px solid',
      borderRadius: '8px',
      borderColor: mode('#DBD9D7', 'whiteAlpha.50')(props),
      bg: 'white',
      // _hover: {
      //   bg: mode('blue.200', 'whiteAlpha.100')(props),
      // },
      _focus: {
        background: 'white',
        backgroundColor: 'white',
      },
    },
    icon: {
      variant: 'filled',
    },
  };
};
const Select = {
  variants: {
    filledBlue: variantPrimary,
    outlineWhite: variantSecondary,
  },
  baseStyle: (props: SelectProps) => ({
    field: {
      _focus: {
        background: props.bg || 'gray.100',
        backgroundColor: props.bg || 'gray.100',
      },

      // FIXME: Apparent bug in Chakra - background color for Select is overridden with 'white', this manually copies
      //        background color over if one is specified
      '& > option': {
        background: props.bg,
        backgroundColor: props.bg,
      },
    },
  }),
  defaultProps: {
    variant: 'filledBlue',
  },
};

export default Select;
