const ImageStrip = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    margin: 'calc(0.5rem*6) 0',
    img: {
      width: ['80px', null, '165px', '300px'],
      height: '100%',
      border: '.25rem solid white',
      borderRadius: '.25rem',
      marginTop: '-24px',
      boxShadow: '0 19px 38px rgba(0,0,0,0.3),0 15px 12px rgba(0,0,0,0.22)',
      _odd: {
        transform: 'rotate(-7deg)',
        zIndex: 1,
      },
      _even: {
        transform: 'rotate(7deg)',
        zIndex: 2,
      },
    },
  },
};

export default ImageStrip;
