const Banner = {
  parts: ['container', 'overlay', 'content', 'actions', 'media', 'mediaDarken'],
  baseStyle: {
    container: {
      flexDirection: 'column',
      position: 'relative',
      width: 'full',
      height: 'fit-content',
    },
    overlay: {
      position: { lg: 'absolute' },
      backgroundColor: 'gray.50',
      opacity: { lg: 0.9 },
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      padding: 8,
      width: { base: '100%', lg: '450px' },
      height: 'fit-content',
      zIndex: { lg: 2 },
      margin: 'auto',
    },
    content: {
      paddingY: 4,
      textAlign: 'center',
    },
    actions: {
      width: 'fit-content',
    },
    media: {
      width: 'full',
      height: 'full',
      position: 'relative',
      maxHeight: '560px',
      video: {
        maxHeight: '560px',
        width: '100%',
        objectFit: 'cover',
      },
    },
    mediaDarken: {
      position: 'absolute',
      width: 'full',
      height: 'full',
      backgroundColor: { lg: 'black' },
      opacity: 0.5,
      zIndex: 1,
    },
  },
};

export default Banner;
