import type { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { mode } from '@chakra-ui/theme-tools';

const Table = {
  baseStyle: (props: StyleFunctionProps) => ({
    th: {
      textAlign: 'left',
      backgroundColor: 'blue.50',
      borderBottom: '1px solid',
      borderBottomColor: 'gray.300',
      color: 'blue.700',
    },
    td: {
      borderBottom: '1px',
      borderColor: 'gray.300',
      _notLast: {
        borderRight: 'solid 1px',
        borderRightColor: 'gray.300',
      },
    },
    tr: {
      borderLeft: 'none',
      borderRight: 'none',
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    thead: {
      backgroundColor: 'blue.50', // HEADER_2 styling
      tr: {
        color: 'blue.800',
        fontWeight: 600,
        td: {
          p: {
            fontSize: { base: '12px', md: '14px', lg: '16px' },
          },
          fontSize: { base: '12px', md: '14px', lg: '16px' },
          paddingX: { base: '2px', sm: '4px', md: '8px' },
          paddingY: { base: '4px', md: '8px', lg: '12px', xl: '14px' },
          textAlign: 'center',
        },
      },
    },
    tbody: {
      tr: {
        td: {
          p: {
            fontSize: { base: '12px', md: '14px', lg: '16px' },
          },
          fontSize: { base: '12px', md: '14px', lg: '16px' },
          paddingX: { base: '2px', sm: '4px', md: '8px' },
          paddingY: { base: '4px', md: '8px', lg: '12px', xl: '14px' },
          background: 'transparent',
        },
      },
    },
    tfoot: {
      backgroundColor: 'gray.50',
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  }),
};

export default Table;
