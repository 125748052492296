import type { TextProps } from '@chakra-ui/react';

const TextV2 = {
  variants: {
    primary: ({ colorScheme }: TextProps) => ({
      color: colorScheme ? `${colorScheme}.800` : `gray.1002`,
      fontSize: { base: '15px', lg: '16px'},
      lineHeight: { base: '22px', lg: '24px'},
      // marginBottom: { base: '22px', lg: '24px' }, THW Removed for now because it creates issues for several components that do not need margin bottom

    }),
  },
  baseStyle: (props: any) => ({
    // Add margin to end of plain paragraphs
    marginBlockEnd: props.as === 'p' ? 2 : undefined,
  }),
  defaultProps: {
    variant: 'primary',
  },
};

export default TextV2;
