import React from 'react';

import type { FeatureCard as FeatureCardComponent } from '../../../design-library/Card';

const getAccentColor = (
  color: React.ComponentProps<typeof FeatureCardComponent>['accent']
) => {
  switch (color) {
    case 'red':
      return 'red.700';
    case 'blue':
      return 'blue.700';
    case 'orange':
      return 'orange.700';
    case 'green':
      return 'green.700';
  }
  return undefined;
};

const FeatureCard = {
  parts: ['card', 'image', 'accentBar', 'content', 'action'],
  baseStyle: {
    card: {
      maxWidth: '400px',
      alignItems: 'center',
    },
    accentBar: {
      width: 'full',
      height: 2,
      marginTop: 0,
    },
    image: {
      maxWidth: 400,
      maxHeight: 400,
      width: 'full',
    },
    content: {
      width: 'full',
      paddingTop: 4,
      paddingX: [2, 6],
    },
    action: {
      marginTop: 0,
      paddingY: 4,
    },
  },
  variants: {
    primary: ({ accent }: any) => ({
      accentBar: {
        backgroundColor: getAccentColor(accent),
      },
    }),
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default FeatureCard;
