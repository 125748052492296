import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    border: '1px solid #CFCFCF',

    _checked: {
      color: 'grey',
      borderColor: '#CFCFCF',
      background: 'white',
      _before: {
        width: '10px',
        height: '10px',
      },
      _hover: {
        bg: 'none',
        borderColor: '#CFCFCF',
      },
    },

    background: 'white',
  },
});

const Radio = defineMultiStyleConfig({ baseStyle });

export default Radio;
