const ChapterCard = {
  parts: ['icon', 'details'],
  baseStyle: {
    icon: {
      display: 'flex',
      width: 100,
      height: 100,
      borderRadius: 'full',
      backgroundColor: 'blue.200',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.5rem',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: 210,
      alignItems: 'start',
      justifyItems: 'start',
      alignSelf: 'start',
    },
  },
};

export default ChapterCard;
