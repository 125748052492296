const EmployeeCard = {
  parts: ['container', 'image', 'content', 'name', 'title', 'email'],
  baseStyle: {
    container: {
      minWidth: '140px',
      minHeight: '320px',
      textAlign: 'center',
    },
    image: {},
    content: {
      paddingY: 1,
    },
    name: {
      marginBlockEnd: 2,
      fontSize: '16px',
    },
    title: {
      fontSize: '14px',
    },
    email: {
      fontSize: '14px',
    },
  },
};

export default EmployeeCard;
