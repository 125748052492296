const GroupBox = {
  baseStyle: {
    borderRadius: '.25rem',
    padding: '1.5rem',
    border: 'solid 1px',
    borderColor: 'gray.400',
  },
};

export default GroupBox;
