import type { TextProps } from '@chakra-ui/react';

const Text = {
  variants: {
    primary: ({ colorScheme }: TextProps) => ({
      color: colorScheme ? `${colorScheme}.800` : undefined,
    }),
  },
  baseStyle: (props: any) => ({
    // Add margin to end of plain paragraphs
    marginBlockEnd: props.as === 'p' ? 2 : undefined,
  }),
  defaultProps: {
    variant: 'primary',
  },
};

export default Text;
