const Card = {
  parts: ['container', 'card'],

  baseStyle: {
    container: {},
    card: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
      background: 'white',
    },
  },

  variants: {
    doubleBorder: {
      container: {
        padding: '10px',
        background: 'white',
      },
      card: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        border: 'solid .25rem',
        borderColor: 'blue.50',
      },
    },
  },
};

export default Card;
