const AvatarCard = {
  parts: ['card', 'content', 'title', 'action'],
  baseStyle: {
    card: {
      flexDirection: 'column',
      fontSize: '0.9em',
      textAlign: 'center',
    },
    content: {
      paddingY: 2,
    },
    title: {},
    action: {},
  },
};

export default AvatarCard;
