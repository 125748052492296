const Breadcrumb = {
  baseStyle: {
    container: {},
    item: {
      fontSize: { base: '13px' },
    },
    list: {
      display: 'inline',
    },
    link: {},
    separator: {
      marginInlineStart: { base: '4px', md: '8px' },
      marginInlineEnd: { base: '4px', md: '8px' },
    },
  },
};

export default Breadcrumb;
