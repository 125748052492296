const HeadlineDividerV2 = {
  parts: ['container', 'divider', 'text'],
  baseStyle: {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      marginBottom: '1.5rem',
    },
    divider: {
      content: '""',
      borderBottomColor: `red.1001`,
      borderBottom: '4px solid',
      width: '32px',
      color: `red.1000`,
      marginBottom: '20px',
    },
    text: {
      fontFamily: 'Inter',
      fontSize: { base: '13px', md: '14px' },
      fontWeight: 900,
      lineHeight: '22px',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#767676',
      letterSpacing: '.125em',
      wordSpacing: '0.125em',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default HeadlineDividerV2;
