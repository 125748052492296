import {
  DividerColorScheme,
  DividerDirection,
} from '../../design-library/Divider';

const getTextColor = (colorScheme: DividerColorScheme) => {
  switch (colorScheme) {
    case DividerColorScheme.STANDARD:
      return 'black';
    case DividerColorScheme.GRAY:
      return 'gray.600';
    case DividerColorScheme.BLUE:
      return 'blue.800';
    case DividerColorScheme.MIXED:
      return 'blue.800';
    case DividerColorScheme.WHITE:
      return 'white';
  }
};

const getDividerColor = (colorScheme: DividerColorScheme) => {
  switch (colorScheme) {
    case DividerColorScheme.STANDARD:
      return 'black';
    case DividerColorScheme.GRAY:
      return 'gray.400';
    case DividerColorScheme.BLUE:
      return 'blue.500';
    case DividerColorScheme.MIXED:
      return 'red.700';
    case DividerColorScheme.WHITE:
      return 'white';
  }
};

const getMargin = (direction: DividerDirection) => {
  if (direction === DividerDirection.HORIZONTAL) {
    return '0 6px';
  }
  return '6px 0';
};

const Divider = {
  parts: ['text', 'container'],
  baseStyle: {
    container: {
      display: 'flex',
      justifyContent: 'center',
      justifySelf: 'center',
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      flexDirection: 'row',
      textTransform: 'uppercase',
      textAlign: 'center',
      width: 'full',
      height: 'full',
      fontSize: '14px',
      fontWeight: 700,
      _before: {
        content: '""',
        flexBasis: '18px',
        flexGrow: 1,
      },
      _after: {
        content: '""',
        flexBasis: '18px',
        flexGrow: 1,
      },
    },
    text: {
      letterSpacing: '1px',
    },
  },
  variants: {
    primary: ({
      direction,
      colorScheme,
    }: {
      direction: DividerDirection;
      colorScheme: DividerColorScheme;
    }) => ({
      container: {
        flexDirection:
          direction === DividerDirection.HORIZONTAL ? 'row' : 'column',
        paddingX: direction === DividerDirection.VERTICAL ? 6 : '0.5rem',
        paddingY: direction === DividerDirection.VERTICAL ? '0.5rem' : 6,
        _before: {
          borderBottom:
            direction === DividerDirection.HORIZONTAL ? '1px solid' : undefined,
          borderLeft:
            direction === DividerDirection.VERTICAL ? '1px solid' : undefined,
          borderColor: getDividerColor(colorScheme),
        },
        _after: {
          borderBottom:
            direction === DividerDirection.HORIZONTAL ? '1px solid' : undefined,
          borderLeft:
            direction === DividerDirection.VERTICAL ? '1px solid' : undefined,
          borderColor: getDividerColor(colorScheme),
        },
      },
      text: {
        margin: getMargin(direction),
        color: getTextColor(colorScheme),
      },
    }),
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Divider;
