import { CardProps, color } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';

import type FeatureProps from '../../design-library/Feature/Feature/index';

type FeatureColorScheme = NonNullable<CardProps['colorScheme']>;

type FeatureVariant = NonNullable<
  React.ComponentProps<typeof FeatureProps>['variant']
>;

const BackgroundColors: Partial<Record<FeatureColorScheme, string>> = {
  sky: 'wl-blue.sky',
  royal: 'wl-blue.royal',
  navy: 'wl-blue.navy',
  white: 'white',
};

const AccentColors: Partial<Record<FeatureColorScheme, string | undefined>> = {
  sky: 'wl-blue.navy',
  royal: 'wl-blue.sky',
  navy: 'wl-blue.sky',
  white: 'wl-blue.navy',
};

const getAccentColor = (
  colorScheme: FeatureColorScheme = 'sky',
  variant: FeatureVariant = 'primary',
): string | undefined => {
  return variant === 'primary'
    ? AccentColors[colorScheme]
    : BackgroundColors[colorScheme];
};

const getBackgroundColor = (
  colorScheme: FeatureColorScheme = 'sky',
  variant: FeatureVariant = 'primary',
): string | undefined => {
  return variant === 'primary' ? BackgroundColors[colorScheme] : undefined;
};

const getHeadlineColor = (
  colorScheme: FeatureColorScheme = 'sky',
  variant: FeatureVariant = 'primary',
): string | undefined => {
  return variant === colorScheme ? AccentColors[colorScheme] : undefined;
};

const getOverlineColor = (
  colorScheme: FeatureColorScheme = 'sky',
  variant: FeatureVariant = 'primary',
): string | undefined => {
  return variant === colorScheme ? AccentColors[colorScheme] : undefined;
};

const Feature = extendTheme({
  parts: [
    'feature',
    'header',
    'overline',
    'heading',
    'headline',
    'content',
    'copy',
  ],
  baseStyle: ({ colorScheme }: CardProps) => ({
    feature: {
      backgroundColor: getBackgroundColor(colorScheme),
      maxWidth: '1536px',
    },
    header: {
      position: 'relative',
      textAlign: 'center',
    },
    overline: {},
    heading: {
      color: colorScheme === 'sky' ? `wl-blue.navy` : `white`,
      fontSize: { base: '32px', lg: '48px', xl: '56px' },
      lineHeight: { base: '36px', lg: '64px' },
      letterSpacing: '-.035em',
      wordSpacing: '0.075em',
      textAlign: 'center',
    },
    content: {
      position: 'relative',
      [`p`]: { color: getAccentColor(colorScheme) },
    },
    copy: {},
  }),
  variants: {
    primary: ({ colorScheme }: CardProps) => ({
      feature: {},
      header: {},
      overline: {
        color: getOverlineColor(colorScheme, 'primary'),
      },
      heading: {
        color: getHeadlineColor(colorScheme, 'primary'),
      },
      content: {},
      copy: {},
    }),
    center: {
      feature: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      header: {
        maxWidth: { base: '642px', lg: 'none' },
        marginBottom: '32px',
      },
      overline: {
        marginBottom: '20px',
      },

      heading: {},
      content: {},
      copy: {},
    },
    promo: {
      feature: {},
      header: {},
      overline: {},
      heading: {},
      content: {},
      copy: {},
    },
    card: {
      heading: {
        fontSize: '32px',
      },
      content: {
        width: 'full',
        img: {
          width: 'full',
        }
      },
    },
    findLocal: {
      feature: {},
      header: {},
      overline: {},
      heading: {},
      content: {},
      copy: {},
    },
    findRep: {
      feature: {},
      header: {
        marginBottom: '16px',
      },
      overline: {
        marginBottom: { base: '20px', lg: '24px' },
      },
      heading: {
        textAlign: {
          base: 'center',
          lg: 'left',
        },
      },
      content: {},
      copy: {},
    },
    image: {
      feature: {},
      header: {
        position: 'static',
      },
      overline: {},
      heading: {},
      content: {},
      copy: {},
    },
  },
  sizes: {
    small: {},
    medium: {
      fontSize: '32px',
    },
    large: {},
  },
  sm: {},
  md: {},
  lg: {},
  defaultProps: {
    variant: 'primary',
  },
});

export default Feature;
