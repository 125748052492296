import type { HeadingProps } from '@chakra-ui/react';

type HeadingColorScheme = NonNullable<HeadingProps['colorScheme']>;
type HeadingVariant = NonNullable<HeadingProps['variant']>;

const AccentColors: Partial<Record<HeadingColorScheme, string | undefined>> = {
  sky: 'wl-blue.sky',
  lightblue: 'wl-blue.sky',
  royal: 'wl-blue.royal',
  navy: 'wl-blue.navy',
  white: 'white',
};

const OverlineAccentColors: Partial<
  Record<HeadingColorScheme, string | undefined>
> = {
  sky: 'wl-blue.royal',
  lightblue: `#00549F`,
  darkblue: '#B5D5F4',
  royal: 'wl-blue.sky',
  navy: 'wl-blue.sky',
  white: 'wl-blue.navy',
};

const getAccentColor = (
  colorScheme: HeadingColorScheme = 'sky',
  variant: HeadingVariant = 'primary',
): string | undefined => {
  return variant === 'primary'
    ? AccentColors[colorScheme]
    : variant === 'overline'
    ? OverlineAccentColors[colorScheme]
    : undefined;
};

const HeadingV2 = {
  baseStyle: {
    color: 'wl-blue.navy',
    sup: {
      fontFamily: 'body',
      verticalAlign: 'super',
    },
    [`.currency`]: {
      fontFamily: 'Portada',
    },
  },
  variants: {
    primary: ({ colorScheme }: HeadingProps) => ({
      color: colorScheme
        ? getAccentColor(colorScheme, 'primary')
        : `wl-blue.navy`,
    }),
    overline: ({ colorScheme }: HeadingProps) => ({
      color: colorScheme
        ? getAccentColor(colorScheme, 'overline')
        : `gray.1001`,
      fontSize: '14px',
      fontFamily: 'body',
      fontWeight: 'black',
      textTransform: 'uppercase',
      lineHeight: 'shorter',
      letterSpacing: 'widest',
      wordSpacing: '0.125em',
      marginBottom: '1.5rem !important',
    }),
  },
  sizes: {
    // H1
    '3xl': {
      marginBlockEnd: ['0.33rem', '0.5rem', '0.67em', '0.83em'],
      fontSize: ['36px', null, null, null, null, '56px', '64px'],
      lineHeight: ['2.5rem', null, null, null, null, '4.375rem'],
      [`sup`]: {
        fontSize: '12px',
        top: ['-.75em', null, null, null, null, '-1.5em'],
      },
    },
    // H2
    '2xl': {
      marginBlockEnd: ['0.25rem', '0.4rem', '0.5em', '0.75em'],
      fontSize: ['32px', null, null, null, null, '48px', '56px'],
      lineHeight: ['36px', null, null, null, null, '64px'],
      [`sup`]: {
        fontSize: '12px',
        top: [`-.60em`, null, null, null, null, `-1.2em`],
      },
    },
    // H3
    xl: {
      marginBlockEnd: ['0.2rem', '0.3rem', '0.4em', '0.66em'],
      fontSize: ['24px', null, null, null, null, '32px'],
      [`sup`]: {
        fontSize: '10px',
        top: `-.60em`,
      },
    },
    // H4
    lg: {
      marginBlockEnd: ['0.15rem', '0.25rem', '0.3em', '0.5em'],
      fontSize: ['20px', null, null, null, null, '24px'],
      [`sup`]: {
        fontSize: '10px',
        top: `-.20em`,
      },
    },
    // H5
    md: {
      marginBlockEnd: ['0.125rem', '0.175rem', '0.2em', '0.33em'],
      fontSize: ['18px', null, null, null, null, '20px'],
      [`sup`]: {
        fontSize: '10px',
        top: `-.1em`,
      },
    },
    // H6
    sm: {
      marginBlockEnd: ['0.1rem', '0.15em', '0.25em'],
      fontSize: ['16px', null, null, null, null, '18px'],
      [`sup`]: {
        fontSize: '10px',
        top: `-.1em`,
      },
    },
    xs: {
      marginBlockEnd: ['0.1rem', '0.15em', '0.25em'],
      fontSize: '14px',
      lineHeight: '20px',
    },
    xxs: {
      marginBlockEnd: ['0.1rem', '0.15em', '0.25em'],
      fontSize: '13px',
      lineHeight: '18px',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default HeadingV2;
