const QuoteCard = {
  parts: ['container', 'frame', 'overlay'],
  baseStyle: {
    container: {
      position: 'relative',
      margin: '0 0 24px 24px',
      zIndex: 1,
      height: '320px',
      width: '310px',
      textAlign: 'center',
    },
    frame: {
      position: 'absolute',
      border: '2px solid #003366',
      height: '100%',
      width: '100%',
      zIndex: '-1',
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: 4,
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      boxShadow:
        '0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%), 0 12.5px 10px rgb(0 0 0 / 6%), 0 22.3px 17.9px rgb(0 0 0 / 7%), 0 41.8px 33.4px rgb(0 0 0 / 9%), 0 100px 80px rgb(0 0 0 / 12%);',
    },
  },
  sizes: {},
  variants: {
    topLeft: {
      frame: {
        top: '-5%',
        left: '-5%',
      },
    },
    bottomLeft: {
      frame: {
        top: '5%',
        left: '-5%',
      },
    },
    topRight: {
      frame: {
        top: '-5%',
        left: '5%',
      },
    },
    bottomRight: {
      frame: {
        top: '5%',
        left: '5%',
      },
    },
  },
  defaultProps: {
    variant: 'topLeft',
  },
};

export default QuoteCard;
