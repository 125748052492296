import type { ContentWarningProps } from '../../design-library/ContentWarning/ContentWarning';

const ContentWarning = {
  baseStyle: ({ color }: ContentWarningProps) => ({
    backgroundColor: `${color}.300`,
    padding: '1rem',
  }),
};

export default ContentWarning;
