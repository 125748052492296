import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { StyleProps } from '@chakra-ui/styled-system';
import React from 'react';

import { DividerColorScheme, DividerDirection } from './index';

export interface DividerProps extends StyleProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  direction?: DividerDirection;
  colorScheme?: DividerColorScheme;
  children: React.ReactNode;
}

const Divider: React.FC<DividerProps> = ({
  children,
  as = 'h2',
  direction = DividerDirection.HORIZONTAL,
  colorScheme = DividerColorScheme.STANDARD,
  ...rest
}) => {
  const hasChildren = React.Children.count(children) > 0;
  const styles = useMultiStyleConfig('Divider', { direction, colorScheme });

  return (
    <Box
      as={as}
      __css={styles.container}
      role={hasChildren ? undefined : 'separator'}
      {...rest}
    >
      {hasChildren && <Box __css={styles.text}>{children}</Box>}
    </Box>
  );
};

export default Divider;
