const DetailsBlock = {
  parts: [
    'container',
    'title',
    'content',
    'image',
    'summary',
    'details',
    'button',
  ],
  baseStyle: {
    container: {
      width: 'full',
    },
    title: {
      padding: 3,
      backgroundColor: 'blue.800',
      color: 'white',
      width: 'full',
      fontSize: 'lg',
      fontWeight: '700',
    },
    content: {
      gridTemplateColumns: ['1fr', null, null, '2fr 3fr'],
      gridColumnGap: 4,
    },
    image: {
      paddingY: 2,
    },
    summary: {
      paddingY: 2,
      justifySelf: 'center',
    },
    details: {
      backgroundColor: 'blue.50',
      padding: 4,
    },
    button: {
      alignSelf: 'end',
    },
  },
};

export default DetailsBlock;
