import type { ChakraStyledOptions } from '@chakra-ui/react';

const AccentColors: Partial<
  Record<ChakraStyledOptions['colorScheme'], string | undefined>
> = {
  gray: `gray.1002`,
  [`gray.70`]: `gray.70`,
  charcoal: `gray.1003`,
  navy: `wl-blue.navy`,
  sky: `wl-blue.sky`,
  royal: `wl-blue.royal`,
  red: `#EA0021`,
  midnight: '#011F3C',
};

const getTextColor = (
  colorScheme: ChakraStyledOptions['colorScheme'] = 'gray',
  variant: ChakraStyledOptions['variant'] = 'primary',
): string | undefined => {
  return variant === 'primary'
    ? AccentColors[colorScheme]
    : AccentColors[colorScheme];
};
const DecorativeText = {
  baseStyle: (props: any) => ({
    // Add margin to end of plain paragraphs
    marginBlockEnd: props.as === 'p' ? 2 : undefined,
    fontFamily: 'Inter',
    fontWeight: 'light',
  }),
  variants: {
    primary: ({ colorScheme }: ChakraStyledOptions) => ({
      color: getTextColor(colorScheme, 'primary'),
    }),
    secondary: ({ colorScheme }: ChakraStyledOptions) => ({
      color: colorScheme ? `${colorScheme}` : `wl-blue.navy`,
      fontFamily: 'Inter.400',
    }),
  },
  sizes: {
    lg: {
      lineHeight: { base: '26px', lg: '32px' },
      fontSize: { base: '18px', lg: '22px' },
    },
    sm: {
      lineHeight: { base: '20px', lg: '22px' },
      fontSize: { base: '14px', lg: '15px' },
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'lg',
  },
};

export default DecorativeText;
