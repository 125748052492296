const SubjectBlock = {
  parts: ['block', 'subject', 'content'],
  baseStyle: {
    block: {
      flexDirection: ['column', 'row'],
      justifyContent: 'space-between',
      paddingY: 4,
    },
    subject: {
      textAlign: [undefined, 'start'],
      flexBasis: '33%',
      flexShrink: 1,
      color: 'blue.800',
      marginRight: [0, 4, 6, 8],
    },
    content: {
      flexBasis: '67%',
      flexShrink: 0,
      '& h1, h2, h3, h4, h5': {
        _notFirst: {
          marginTop: 4,
        },
        color: 'gray.700',
      },
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default SubjectBlock;
