const getBackground = (scheme: string) => {
  if (scheme === 'yellow') {
    return `#fed34c`;
  } else if (scheme === 'blue') {
    return `wl-blue.sky`;
  }
  return undefined;
};

const getTextColor = (scheme: string) => {
  // If we support dark backgrounds in the future we would need to handle 'white' text here
  return undefined;
};

const getDividerColor = (scheme: string) => {
  if (scheme === 'yellow') {
    // return 'yellow.600';
    return 'white';
  }
  return 'black';
};

const PricingFeature = {
  parts: [
    'container',
    'content',
    'disclosures',
    'headline',
    'arrow',
    'features',
  ],
  baseStyle: ({ colorScheme }: any) => ({
    container: {
      maxWidth: { base: 'full', lg: '320px' },
      bg: getBackground(colorScheme),
      paddingBottom: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    content: {
      maxWidth: '320px',
      padding: 4,
      color: getTextColor(colorScheme),
      'hr, [role=separator]': {
        borderBottomColor: getDividerColor(colorScheme),
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
    headline: {
      padding: 2,
      textAlign: 'center',
      backgroundColor: 'white',
      '*': {
        marginBlockEnd: 0,
      },
      h2: {
        fontFamily: '"Inter", helvetica, arial, sans-serif',
        sup: {
          verticalAlign: 'super',
        },
      },
    },
    arrow: {
      width: 0,
      height: 0,
      borderLeft: '144px solid transparent',
      borderRight: '144px solid transparent',
      borderTop: '35px solid white',
      margin: '0 auto',
    },
    features: {
      fontSize: '14px',
    },
    disclosures: {
      width: 'full',
      backgroundColor: 'white',
      fontSize: '12px',
      padding: 2,
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default PricingFeature;
