const FormStatusFeedback = {
  parts: ['container', 'indicator'],
  baseStyle: ({ type }: any) => ({
    container: {
      position: 'relative',
      backgroundColor: 'white',
      color: type === 'success' ? `wl-blue.navy` : 'white',
      textAlign: 'center',
      padding: { base: '2.5rem', md: '5rem' },
      margin: '1rem',
      border: '1px solid #E7E6E5',
      borderRadius: '16px',
    },
    indicator: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '48px',
      height: '48px',
      margin: '0 auto',
      left: 0,
      right: 0,
      bottom: '-24px',
      color: type === 'success' ? `wl-blue.royal` : `white`,
      background: type === 'success' ? `white` : undefined,
      borderRadius: '99px',
      borderWidth: '2px',
      borderColor: 'wl-blue.royal',
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default FormStatusFeedback;
