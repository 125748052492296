const List = {
  parts: ['container', 'item', 'icon'],
  baseStyle: () => ({
    container: {
      paddingLeft: ['19px'],
      paddingY: 3,
    },
    item: {},
    icon: {},
  }),
};

export default List;
