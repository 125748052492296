import type { ButtonProps } from '@chakra-ui/react';
import React from 'react';

import type WLButton from '../../design-library/Button';

type ButtonColorScheme = NonNullable<ButtonProps['colorScheme']>;
type ButtonVariant = NonNullable<
  React.ComponentProps<typeof WLButton>['variant']
>;
// added new prop wl-blue to all color methods so that we can revert buttons which have been redesigned to
// new styles and replace old styles for buttons that are currently experiencing styling errors - THW
const BackgroundColors: Partial<Record<ButtonColorScheme, string>> = {
  blue: 'wl-blue.royal',
  navy: 'white',
  royal: 'white',
  'wl-blue': 'wl-blue.royal',
  'wl-blue-secondary': 'wl-blue.sky',
  lightblue: 'wl-blue.sky',
  red: 'red.1000',
  white: 'white',
  'wl-white': 'white',
};
const AccentColors: Partial<Record<ButtonColorScheme, string | undefined>> = {
  blue: 'white',
  'wl-blue': 'white',
  'wl-blue-secondary': 'wl-blue.royal',
  lightblue: 'wl-blue.royal',
  red: 'white',
  white: 'blue.700',
  'wl-white': 'wl-blue.royal',
};

// added for redesign THW

const BorderColors: Partial<Record<ButtonColorScheme, string | undefined>> = {
  'wl-blue': 'wl-blue.royal',
  red: 'red.1000',
  white: 'white',
  'wl-white': 'white',
};
const HoverBackgroundColors: Partial<Record<ButtonColorScheme, string>> = {
  blue: 'white',
  'wl-blue': 'white',
  'wl-blue-secondary': 'wl-blue.royal',
  lightblue: 'blue.700',
  red: 'white',
  white: 'wl-blue.royal',
  'wl-white': 'wl-blue.royal',
};
const HoverTextColors: Partial<Record<ButtonColorScheme, string | undefined>> =
  {
    blue: 'wl-blue.royal',
    'wl-blue': 'wl-blue.royal',
    'wl-blue-secondary': 'white',
    lightblue: 'white',
    red: 'red.1000',
    white: 'white',
    'wl-white': 'white',
  };

const HoverBorderColors: Partial<
  Record<ButtonColorScheme, string | undefined>
> = {
  white: 'white',
  // added for redesign THW
  'wl-white': 'white',
};

const getTextColor = (
  colorScheme: ButtonColorScheme = 'blue',
  variant: ButtonVariant = 'solid',
): string | undefined => {
  return variant === 'solid' ||
    variant === 'primary' ||
    variant === 'secondaryBlue' ||
    variant === 'primaryReverse' ||
    variant === 'secondaryWhite'
    ? AccentColors[colorScheme]
    : BackgroundColors[colorScheme];
};
const getBgColor = (
  colorScheme: ButtonColorScheme = 'blue',
  variant: ButtonVariant = 'solid',
): string | undefined => {
  return variant === 'solid' ||
    variant === 'primary' ||
    variant === 'secondaryBlue' ||
    variant === 'secondaryWhite' ||
    variant === 'primaryReverse'
    ? BackgroundColors[colorScheme]
    : undefined;
};

const getBorderColor = (
  colorScheme: ButtonColorScheme = 'wl-blue',
  variant: ButtonVariant = 'primary',
): string | undefined => {
  return variant === 'primary' || variant === 'secondaryWhite'
    ? BorderColors[colorScheme]
    : variant === 'primaryReverse'
    ? AccentColors[colorScheme]
    : BackgroundColors[colorScheme];
};

const getHoverTextColor = (
  colorScheme: ButtonColorScheme = 'blue',
  variant: ButtonVariant = 'solid',
): string | undefined => {
  return variant === 'solid' ||
    variant === 'primary' ||
    variant === 'secondaryBlue' ||
    variant === 'primaryReverse' ||
    variant === 'secondaryWhite'
    ? HoverTextColors[colorScheme]
    : AccentColors[colorScheme];
};
const getHoverBgColor = (
  colorScheme: ButtonColorScheme = 'blue',
  variant: ButtonVariant = 'solid',
): string | undefined => {
  return variant === 'solid' ||
    variant === 'primary' ||
    variant === 'secondaryBlue' ||
    variant === 'secondaryWhite' ||
    variant === 'primaryReverse'
    ? HoverBackgroundColors[colorScheme]
    : BackgroundColors[colorScheme];
};

const getHoverBorderColor = (
  colorScheme: ButtonColorScheme = 'wl-white',
  variant: ButtonVariant = 'secondaryWhite',
): string | undefined => {
  return variant === 'secondaryWhite' || variant === 'secondaryBlue'
    ? HoverBorderColors[colorScheme]
    : BorderColors[colorScheme];
};

const Button = {
  baseStyle: {
    height: '44px',
    // border: '2px',
    borderRadius: '56px',
    textAlign: 'center',
    whiteSpace: 'normal',
    cursor: 'pointer',
    // THW - add padding later, discuss how width effects padding 20px left right 1px top
    // bottom, min width 168px
    // also discuss breakpoints
  },
  variants: {
    solid: ({ colorScheme }: ButtonProps) => ({
      paddingLeft: '20px',
      paddingRight: '20px',
      border: '2px',
      bg: getBgColor(colorScheme, 'solid'),
      color: getTextColor(colorScheme, 'solid'),
      borderColor: getBorderColor(colorScheme, 'solid'),
      _hover: {
        bg: getHoverBgColor(colorScheme, 'solid'),
        color: getHoverTextColor(colorScheme, 'solid'),
        borderColor: getBorderColor(colorScheme, 'solid'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'secondaryWhite'),
          transform: `translate`,
        },
      },
      _active: {
        bg: getHoverBgColor(colorScheme, 'solid'),
        color: getHoverTextColor(colorScheme, 'solid'),
        borderColor: getBorderColor(colorScheme, 'solid'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'secondaryWhite'),
          transform: `translate`,
        },
      },
      svg: {
        fill: getTextColor(colorScheme, 'secondaryBlue'),
      },
    }),
    secondaryBlue: ({ colorScheme }: ButtonProps) => ({
      minW: { base: '156px', lg: '168px' },
      paddingRight: '20px',
      paddingLeft: '20px',
      fontWeight: '700',
      border: '2px',
      bg: getBgColor(colorScheme, 'secondaryBlue'),
      color: getTextColor(colorScheme, 'secondaryBlue'),
      borderColor: getBorderColor(colorScheme, 'secondaryBlue'),
      _hover: {
        bg: getHoverBgColor(colorScheme, 'secondaryBlue'),
        color: getHoverTextColor(colorScheme, 'secondaryBlue'),
        // borderColor: getBorderColor(colorScheme, 'secondaryBlue'),
        borderColor: 'white',
        svg: {
          fill: getHoverTextColor(colorScheme, 'secondaryBlue'),
        },
      },
      _groupHover: {
        bg: getHoverBgColor(colorScheme, 'secondaryBlue'),
        color: getHoverTextColor(colorScheme, 'secondaryBlue'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'secondaryBlue'),
        },
      },
      svg: {
        fill: getTextColor(colorScheme, 'secondaryBlue'),
      },
    }),
    secondaryWhite: ({ colorScheme }: ButtonProps) => ({
      minW: { base: '156px', lg: '168px' },
      paddingRight: '20px',
      paddingLeft: '20px',
      fontWeight: '700',
      border: '2px',
      bg: getBgColor(colorScheme, 'secondaryWhite'),
      color: getTextColor(colorScheme, 'secondaryWhite'),
      borderColor: getBorderColor(colorScheme, 'secondaryWhite'),
      _hover: {
        bg: getHoverBgColor(colorScheme, 'secondaryWhite'),
        color: getHoverTextColor(colorScheme, 'secondaryWhite'),
        borderColor: getHoverBorderColor(colorScheme, 'secondaryWhite'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'secondaryWhite'),
        },
      },
      _groupHover: {
        bg: getHoverBgColor(colorScheme, 'secondaryWhite'),
        color: getHoverTextColor(colorScheme, 'secondaryWhite'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'secondaryWhite'),
        },
      },
      svg: {
        fill: getTextColor(colorScheme, 'secondaryWhite'),
      },
    }),
    primary: ({ colorScheme }: ButtonProps) => ({
      minW: { base: '156px', lg: '168px' },
      paddingRight: '20px',
      paddingLeft: '20px',
      fontWeight: '700',
      border: '2px',
      bg: getBgColor(colorScheme, 'primary'),
      color: getTextColor(colorScheme, 'primary'),
      borderColor: getBorderColor(colorScheme, 'primary'),
      _hover: {
        bg: getHoverBgColor(colorScheme, 'primary'),
        color: getHoverTextColor(colorScheme, 'primary'),
        borderColor: getBorderColor(colorScheme, 'primary'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'primary'),
        },
      },
      _groupHover: {
        bg: getHoverBgColor(colorScheme, 'primary'),
        color: getHoverTextColor(colorScheme, 'primary'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'primary'),
        },
      },
      svg: {
        fill: getTextColor(colorScheme, 'primary'),
      },
    }),
    primaryReverse: ({ colorScheme }: ButtonProps) => ({
      minW: { base: '156px', lg: '168px' },
      paddingRight: '20px',
      paddingLeft: '20px',
      fontWeight: '700',
      border: '2px',
      bg: getBgColor(colorScheme, 'primaryReverse'),
      color: getTextColor(colorScheme, 'primaryReverse'),
      svg: {
        fill: getTextColor(colorScheme, 'primaryReverse'),
      },
      borderColor: getBorderColor(colorScheme, 'primaryReverse'),
      _hover: {
        bg: getHoverBgColor(colorScheme, 'primaryReverse'),
        color: getHoverTextColor(colorScheme, 'primaryReverse'),
        borderColor: getBorderColor(colorScheme, 'primaryReverse'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'primaryReverse'),
        },
      },
      _groupHover: {
        bg: getHoverBgColor(colorScheme, 'primaryReverse'),
        color: getHoverTextColor(colorScheme, 'primaryReverse'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'primaryReverse'),
        },
      },
    }),
    primaryMicro: ({ colorScheme }: ButtonProps) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      w: '32px',
      h: '32px',
      border: '2px',
      borderRadius: '100%',
      bg: getBgColor(colorScheme, 'primary'),
      svg: {
        fill: getTextColor(colorScheme, 'primary'),
      },
      borderColor: getBorderColor(colorScheme, 'primary'),
      _hover: {
        bg: getHoverBgColor(colorScheme, 'primary'),
        borderColor: getBorderColor(colorScheme, 'primary'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'primary'),
        },
      },
      _groupHover: {
        bg: getHoverBgColor(colorScheme, 'primary'),
        svg: {
          fill: getHoverTextColor(colorScheme, 'primary'),
        },
      },
    }),
    outline: ({ colorScheme }: ButtonProps) => {
      return {
        border: 'solid 2px',
        bg: getBgColor(colorScheme, 'outline'),
        color: getTextColor(colorScheme, 'outline'),
        _hover: {
          bg: getHoverBgColor(colorScheme, 'outline'),
          color: getHoverTextColor(colorScheme, 'outline'),
        },
      };
    },
    iconOutline: ({ colorScheme }: ButtonProps) => ({
      color: getTextColor(colorScheme, 'outline'),
      border: 'solid 2px',
      borderColor: getTextColor(colorScheme, 'outline'),
      _hover: {
        backgroundColor: getHoverTextColor(colorScheme, 'outline'),
        borderColor: getHoverTextColor(colorScheme, 'outline'),
      },
    }),
  },
  // TODO: these will be modified in the future
  // https://chakra-ui.com/docs/theming/theme#spacing
  sizes: {
    lg: {
      px: '56px',
      minHeight: 10,
      height: 'fit-content',
    },
    md: {
      px: '56px',
      minHeight: 10,
      height: 'fit-content',
    },
    sm: {
      px: '56px',
      minHeight: 10,
      height: 'fit-content',
    },
    xs: {
      px: '56px',
      minHeight: 10,
      height: 'fit-content',
    },
  },
};

export default Button;
