const Statistic = {
  parts: ['container', 'stat', 'context'],
  baseStyle: ({ hasIcon }: any) => ({
    container: {
      flexDirection: hasIcon ? 'column' : ['column', 'row'],
    },
    stat: {
      color: 'orange.600',
      fontSize: '6xl',
      fontWeight: 700,
      paddingX: 4,
    },
    context: {
      textAlign: hasIcon ? 'center' : 'left',
    },
  }),
  sizes: {},
  variants: {},
  defaultProps: {},
};

export default Statistic;
