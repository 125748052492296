const buildGradient = (colorScheme: 'blue' | 'red') => {
  const fromColor = colorScheme === 'blue' ? 'blue.800' : 'red.700';
  const toColor = colorScheme === 'blue' ? 'blue.700' : 'red.500';
  return `linear(to-b, ${fromColor}, 80%, ${toColor})"`;
};

const FramedImage = {
  parts: ['container', 'primaryFrame', 'secondaryFrame'],
  baseStyle: ({ colorScheme }: { colorScheme: 'blue' | 'red' }) => ({
    container: {
      flexDirection: ['column', null, 'row'],
    },
    primaryFrame: {
      bgGradient: buildGradient(colorScheme),
      flexBasis: '50%',
      padding: [1, 2, 4, 8],
    },
    secondaryFrame: {
      flexBasis: '50%',
      backgroundColor: 'gray.200',
      padding: [1, 2, 4, 8],
    },
  }),
};

export default FramedImage;
