const IconCard = {
  parts: ['container', 'icon'],
  baseStyle: {
    container: {
      position: 'relative',
      margin: 'auto',
      width: { base: '100%', md: '50%' },
      maxWidth: 'container.xl',
      backgroundColor: 'white',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '32px',
    },
    icon: {
      position: 'absolute',
      top: 0,
      boxSize: '60px',
      backgroundColor: 'white',
      marginTop: '-32px',
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '50%',
      boxShadow: '0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)',
    },
  },
};

export default IconCard;
