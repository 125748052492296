const BioCard = {
  parts: ['card', 'name', 'title', 'location', 'link'],
  baseStyle: {
    card: {
      width: ['100%', '100%', '330px', '360px', '390px'],
      background: 'white',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      border: 'solid 1px',
      borderColor: 'gray.50',
      borderRadius: 'md',
      boxShadow: 'md',

      _hover: {
        boxShadow: 'dark-lg',
      },
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: '4 1 100px',
      padding: '1rem',
    },
    name: {
      fontSize: '1rem',
      lineHeight: '1.2rem',
      fontWeight: '700',
      marginBottom: '.5rem',
      paddingBottom: '.5rem',
      borderBottom: '1px solid',
      borderColor: 'gray.300',
    },
    title: {
      fontSize: '.75rem',
      lineHeight: '1rem',
      fontWeight: '700',
      margin: '0 0 .25rem 0',
      padding: 0,
    },
    location: {
      fontSize: '.75rem',
      lineHeight: '1rem',
      color: 'gray.500',
      margin: 0,
      padding: 0,
    },
    link: {
      padding: 0,
      marginTop: '1rem',
      textAlign: 'left',
      alignItems: 'left',
      textDecoration: 'none',
      color: 'gray.700',
      _hover: {
        textDecoration: 'underline',
      },
    },
    image: {
      img: {
        borderRadius: '8px 0 0 8px',
      },
    },
  },
};

export default BioCard;
