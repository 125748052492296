const ContactActionBox = {
  parts: [
    'container',
    'contactSection',
    'actionSection',
    'actionText',
    'action',
  ],
  baseStyle: {
    container: {
      fontSize: '0.9em',
    },
    contactSection: {
      backgroundColor: 'gray.100',
      paddingX: [2, 6],
      paddingY: 6,
      width: 'full',
      margin: 0,
    },
    actionSection: {
      backgroundColor: 'blue.700',
      paddingX: [2, 6],
      paddingY: 6,
      width: 'full',
      margin: 0,
      color: 'white',
    },
    actionText: {
      width: 'full',
      paddingBottom: 4,
    },
    action: {},
  },
};

export default ContactActionBox;
