const HeaderCard = {
  parts: ['card', 'header', 'content'],
  baseStyle: ({ colorScheme }: any) => ({
    card: {
      borderColor: 'gray.400',
      borderWidth: 1,
      width: 'full',
    },
    header: {
      width: 'full',
      backgroundColor: colorScheme === 'blue' ? 'blue.700' : 'red.700',
      color: 'white',
      paddingX: 4,
      paddingY: 2,
      fontWeight: 600,
    },
    content: {
      width: 'full',
      padding: 4,
    },
  }),
};

export default HeaderCard;
