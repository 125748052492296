const NumberBadge = {
  baseStyle: {
    borderRadius: '50%',
    flexShrink: '0',
    flexGrow: '0',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
};

export default NumberBadge;
