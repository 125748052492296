const LinkCardBlue = 'blue.500';

const LinksCard = {
  parts: ['card', 'heading', 'link', 'icon', 'linkHeading', 'details'],
  baseStyle: {
    card: {
      width: ['100%', '100%', '330px', '360px', '390px'],
      background: 'white',
      border: 'solid 1px',
      borderColor: 'gray.50',
      borderRadius: 'md',
      boxShadow: 'md',
      paddingY: 4,
      paddingX: [2, 4],
    },
    heading: {
      color: LinkCardBlue,
      textAlign: 'center',
      marginTop: 2,
    },
    link: {},
    icon: {
      '& svg': {
        width: '3rem',
        height: '3rem',
      },
      color: LinkCardBlue,
    },
    linkHeading: {
      color: LinkCardBlue,
    },
    details: {
      paddingY: 4,
    },
  },
};

export default LinksCard;
